//react imports
import { useState, useEffect } from "react";

//mui imports
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";

//local imports
import { InvestorNavBar, SponsorBar } from "../components/index";
import { getData } from "../api/calls";
import { speakers } from "../data/speakers";

export const Speakers = () => {
  const [open, setOpen] = useState(false);
  const [modalInfo, setModalInfo] = useState({});
  const handleClose = () => setOpen(false);
  const [speakerCountRounded, setSpeakerCountRounded] = useState("");
  const [speakerArr, setSpeakerArr] = useState([]);
  const [showSpeakers, setShowSpeakers] = useState("none");
  const [showLoader, setShowLoader] = useState("block");
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   setLoading(true);
  //   getData(`https://youngstartup.io/api/cwebsite/get_speakers`)
  //     .then((data) => {
  //       setSpeakerArr(data);
  //       setSpeakerCountRounded(Math.floor(Number(data.length) / 10) * 10);
  //     })
  //     .then(() => setLoading(false));
  // }, []);

  const GridItem = ({ speaker, i }) => {
    return (
      <Grid
        className="grid-item hover-card"
        key={i}
        item
        xs={1}
        sm={1}
        lg={1}
        xl={1}
        sx={{
          maxWidth: window.innerWidth < 900 ? "140px" : "340px !important",
        }}
      >
        <Paper
          className="speaker-card"
          style={{
            borderRadius: "10px",
            margin: window.innerWidth < 900 ? ".5rem" : ".5rem",
            padding: window.innerWidth < 900 ? ".25rem": ".5rem",
            alignItems: "stretch",
            backgroundColor: "white",
            justifyContent:'center',
            maxWidth: window.innerWidth < 900 ? "140px !important" : "340px !important",
            height: "95%",
          }}
          elevation={15}
          key={i}
          onClick={() => {
            setModalInfo({
              name: speaker.firstname + " " + speaker.lastname,
              bio: speaker.bio,
              headshotUrl: speaker.speaker_head_shot_to_display,
              company: speaker.company,
            });
            setOpen(true);
          }}
        >
          <img
            src={speaker.speaker_head_shot_to_display}
            loading={i < 20 ? "eager" : "lazy"}
            style={{ borderRadius: "5px", width: window.innerWidth < 900 ? "115px" : "100%", height: "auto" }}
          />
          <div style={{ color: "black", fontSize: window.innerWidth < 900 ? ".9rem" : "1.35rem" }}>
            {speaker.firstname}{ window.innerWidth < 1000 && <br/>} {speaker.lastname}
          </div>
          <div style={{ color: "#D215E9", fontSize: window.innerWidth < 900 ? " .85rem" : "1rem" }}>
            {speaker.company}
          </div>
        </Paper>
      </Grid>
    );
  };

  return (
    <div style={{ backgroundColor: "white", justifyContent: "center" }}>
      <InvestorNavBar />
      <div style={{ display: "flex" }}>

        <div style={{ height: "90vh", overflowY: "scroll", width: "100%" }}>
          <Dialog
            open={open}
            onClose={handleClose}
            sx={{ justifyContent: "center", textAlign: "center" }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              <div>{modalInfo.name}</div>
              <div>{modalInfo.company}</div>
            </DialogTitle>
            <DialogContent>
              <img src={modalInfo.headshotUrl} />
              <DialogContentText
                sx={{ marginTop: "1rem" }}
                id="alert-dialog-description"
              >
                {modalInfo.bio}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} variant="secondary" autoFocus>
                Close
              </Button>
            </DialogActions>
          </Dialog>
          <h1
            id="custom-stop"
            style={{
              textAlign: "center",
              color: "black",
              paddingTop: "5vh",
              fontWeight: "900",
            }}
          >
            {/* RECENTLY FEATURED INVESTORS */}
           FEATURED INVESTORS
          </h1>
          <div
            style={{ display: "flex", width: "100%", justifyContent: "center" }}
          >
            {/* <img src={"/assets/purple-spinner-2.gif"} /> */}
            <Box sx={{ flexGrow: 0 }}>
              <Grid
                container
                spacing={0}
                columns={{ xs: 2, sm: 2, md: 4, lg: 4, xl: 5 }}
                style={{
                  // padding: "0rem",
                  margin: "auto",
                  paddingRight: "5%",
                  justifyContent: "center",
                  paddingLeft: "5%",
                  maxWidth: "1698px",
                }}
                columnSpacing={{ xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }}
              >
                {loading && [...Array(50)].map((item, i)=>(
                  <Skeleton
                    sx={{ borderRadius:'15px', margin: loading && "15px", height:'90%'}}
                    variant="rectangular"
                    key={i}
                    width={window.innerWidth < 900 ? 125 : 266}
                    height={window.innerWidth < 900 ? 190 : 390}
                  ></Skeleton>
                ))}{" "}
                {speakers.map((speaker, i) => (
                  <GridItem speaker={speaker} i={i} />
                ))}
              </Grid>
            </Box>
          </div>
        </div>
        {window.innerWidth > 900 && <SponsorBar />}
      </div>
    </div>
  );
};
