import { InvestorNavBar, SponsorBar, SponsorCards, SponsorsSection } from "../components/index";
import Stack from "@mui/material/Stack";

export const Sponsors = () => {
  return (
    <div>
      <InvestorNavBar />
      <div style={{ display: "flex"}}>

        <div style={{flex:9}}>
          {window.innerWidth > 900 ? <SponsorCards /> : <SponsorsSection /> }
        </div>
        <div>{window.innerWidth > 900 && <SponsorBar />}</div>
      </div>
    </div>
  );
};
