import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { Home, Schedule, Sponsors, Speakers, ElevatorPitch, Presenters, LockScreen } from './pages';

export const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/schedule" element={<Schedule />} />
        <Route path="/sponsors" element={<Sponsors />} />
        <Route path="/speakers" element={<Speakers />} />
        {/* <Route path="/elevator" element={<ElevatorPitch />} /> */}
        <Route path="/presenters" element={<Presenters />} />
      </Routes>
    </BrowserRouter>
  );
}

