import { InvestorNavBar, SponsorBar } from "../components/index";
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";
import { useEffect, useState } from "react";

export const Schedule = () => {
  const [showImg, setShowImg] = useState("flex");
  const [showAgenda, setShowAgenda] = useState("none");
  useEffect(() => {
    var bz = document.createElement("script");
    bz.type = "text/javascript";
    bz.async = true;
    bz.src = "https://organizer.bizzabo.com/widgets/agenda/agenda.js";
    var s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(bz, s);

    setTimeout(()=>{
      setShowImg("none");
      setShowAgenda("block");
    }, 5000)
  });
  return (
    <div>
      <InvestorNavBar />

      <div style={{ display: "flex" }}>
 
        <div
          style={{
            width: "100%",
            height: "90vh",
            overflowY: "scroll",
            // padding: window.innerWidth > 900 && "0% 2.5% 0% 2.5%",
          }}
        >
          <div style={{display:'flex', alignItems:'center', width:'100%', height:'100%', display: showImg}}>
      <img src={"/assets/purple-spinner.gif"} style={{maxWidth:'250px', margin:'auto'}} />
      </div>
      <div style={{display: showAgenda, paddingTop:'2.5%'}}>
          <div
            name="bizzabo-web-agenda"
            data-unique-name="378401"
            data-internal-website-base-url="https://events.bizzabo.com/378401"
          ></div>
          <a
            className="bizzabo-link-class"
            style={{
              textAlign: "right",
              paddingRight: "17px",
              textDecoration: "none",
              width: "100%",
              display: "inline-block",
              marginTop: "5px",
              boxSizing: "border-box",
            }}
            href="https://www.bizzabo.com?utm_source=tickets_widget&utm_medium=powered_by&utm_campaign=tickets_widget"
            target="_blank"
            rel="noopener"
          >
            Powered by Bizzabo
          </a>
          </div>
        </div>
        {window.innerWidth > 900 && <SponsorBar />}
      </div>
    </div>
  );
};
