import Stack from "@mui/material/Stack";

var getUrl = window.location;
var baseUrl =
  getUrl.protocol + "//" + getUrl.host + "/" + getUrl.pathname.split("/")[1];

export const SponsorBar = () => {
  console.log(baseUrl);
  const logoArr = [
    { img_url: "/assets/Brownrudnick.png", website: "https://brownrudnick.com/" },
    { img_url: "/assets/Palladium Capital.png", website: "https://palladiumcapital.com/" },
    { img_url: "/assets/MWE.png", website: "https://www.mwe.com/" },
    { img_url: "/assets/Cartika.png", website: "https://www.cartika.com/" },
    { img_url: "/assets/boardsy.png", website: "https://boardsi.com/" },
    {
      img_url: "/assets/Full Throttle Leads.png",
      website: "https://fullthrottleleads.com/",
    },
    {
      img_url: "/assets/Innovation Center.png",
      website: "https://innovationcenter.tech/",
    },
    {
      img_url: "/assets/JETRO.png",
      website: "https://www.jetro.go.jp/en/",
    },
    { img_url: "/assets/mongotel.png", website: "https://mongotel.com/" },
    { img_url: "/assets/Group 109 (1).png", website: "https://salesqualia.com/" },
    {
      img_url: "/assets/Allstate.png",
      website: "https://www.allstate.com/strategic-ventures/main.htm",
    },
    {
      img_url: "/assets/Energy Foundry.png",
      website: "https://www.energyfoundry.com/",
    },
    { img_url: "/assets/Masa Partners.png", website: "https://masaimpact.com/" },
    { img_url: "/assets/Sherwood.png", website: "https://www.shrwood.com/" },
    { img_url: "/assets/Agency IP.png", website: "https://www.agencyip.com/" },
  ];

  return (
    <div
      style={{
        height: "90vh",
        display: "flex",
        backgroundColor: "white",
        alignItems:"center",
        justifyContent:'center',
        flexDirection:'column',
        margin:'0px !important',
        overflowY: "scroll",
        paddingTop: (window.location.href.includes("sponsors") || window.location.href.includes("elevator") || window.location.href.includes("presenters")) ? "35vh" : "27.5vh"
      }}
    >
      <h4 style={{padding: '1.5% 1.5%', margin:'1% 1%', backgroundColor:'#7D2C82', color:'white', borderRadius:'5px', width:'80%', textAlign:'center'}}>Diamond Sponsors</h4>
      <a  href={"https://brownrudnick.com/"} target="_blank" style={{display:'flex', justifyContent:'center', width:'100%'}}>
            <img
              src={"/assets/Brownrudnick.png"}
              style={{ maxWidth: window.innerHeight < 1000 ? "55%" : "90%", minWidth:'125px'  }}
            />
          </a>
          <a  href={"https://palladiumcapital.com/" } target="_blank" style={{display:'flex', justifyContent:'center', width:'100%'}}>
            <img
              src={"/assets/Palladium Capital.png"}
              style={{ maxWidth: window.innerHeight < 1000 ? "55%" : "90%", minWidth:'125px'  }}
            />
          </a>

          <h4 style={{padding: '1.5% 1.5%', margin:'1% 1%', backgroundColor:'#7D2C82', color:'white', borderRadius:'5px', width:'80%', textAlign:'center'}}>Platinum Sponsor</h4>
          <a  href={"https://www.mwe.com/"} target="_blank" style={{display:'flex', justifyContent:'center', width:'100%'}}>
            <img
              src={"/assets/MWE.png"}
              style={{ maxWidth: window.innerHeight < 1000 ? "55%" : "90%", minWidth:'125px'  }}
            />
          </a>

          <h4 style={{padding: '1.5% 1.5%', margin:'1% 1%',backgroundColor:'#7D2C82', borderRadius:'5px', width:'80%', textAlign:'center', color:'white'}}>Gold Sponsors</h4>
          <a  href={"https://www.agencyip.com/"} target="_blank" style={{display:'flex', justifyContent:'center', width:'100%'}}>
            <img
              src={"/assets/Agency IP.png"}
              style={{ maxWidth: window.innerHeight < 1000 ? "55%" : "90%", minWidth:'125px'  }}
            />
          </a>
          <a  href={"https://boardsi.com/"} target="_blank" style={{display:'flex', justifyContent:'center', width:'100%'}}>
            <img
              src={"/assets/boardsy.png"}
              style={{ maxWidth: window.innerHeight < 1000 ? "55%" : "90%", minWidth:'125px'  }}
            />
          </a>
          <a  href={"https://www.cartika.com/"} target="_blank" style={{display:'flex', justifyContent:'center', width:'100%'}}>
            <img
              src={"/assets/Cartika.png"}
              style={{ maxWidth: window.innerHeight < 1000 ? "55%" : "90%", minWidth:'125px'  }}
            />
          </a>
          <a  href={"https://fullthrottleleads.com/"} target="_blank" style={{display:'flex', justifyContent:'center', width:'100%'}}>
            <img
              src={"/assets/Full Throttle Leads.png"}
              style={{ maxWidth: window.innerHeight < 1000 ? "55%" : "90%", minWidth:'125px'  }}
            />
          </a>
          <a  href={"https://innovationcenter.tech/"} target="_blank" style={{display:'flex', justifyContent:'center', width:'100%'}}>
            <img
              src={"/assets/Innovation Center.png"}
              style={{ maxWidth: window.innerHeight < 1000 ? "55%" : "90%", minWidth:'125px'  }}
            />
          </a>
          <a  href={"https://www.jetro.go.jp/en/"} target="_blank" style={{display:'flex', justifyContent:'center', width:'100%'}}>
            <img
              src={"/assets/JETRO.png"}
              style={{ maxWidth: window.innerHeight < 1000 ? "55%" : "90%", minWidth:'125px'  }}
            />
          </a>
          <a  href={"https://mongotel.com/"} target="_blank" style={{display:'flex', justifyContent:'center', width:'100%'}}>
            <img
              src={"/assets/mongotel.png"}
              style={{ maxWidth: window.innerHeight < 1000 ? "55%" : "90%", minWidth:'125px'  }}
            />
          </a>
          <a  href={"https://salesqualia.com/"} target="_blank" style={{display:'flex', justifyContent:'center', width:'100%'}}>
            <img
              src={"/assets/Group 109 (1).png"}
              style={{ maxWidth: window.innerHeight < 1000 ? "55%" : "90%", minWidth:'125px'  }}
            />
          </a>
          <a  href={"https://www.cartika.com/"} target="_blank" style={{display:'flex', justifyContent:'center', width:'100%'}}>
            <img
              src={"/assets/Sherwood.png"}
              style={{ maxWidth: window.innerHeight < 1000 ? "55%" : "90%", minWidth:'125px'  }}
            />
          </a>
          <br/>
          <h4 style={{padding: '1.5% 1.5%', margin:'1% 1%', backgroundColor:'#7D2C82', color:'white', borderRadius:'5px', width:'80%', textAlign:'center'}}>Venture Sponsors</h4>
          <a  href={"https://www.allstate.com/strategic-ventures/main.htm"} target="_blank" style={{display:'flex', justifyContent:'center', width:'100%'}}>
            <img
              src={"/assets/Allstate.png"}
              style={{ maxWidth: window.innerHeight < 1000 ? "55%" : "90%", minWidth:'125px'  }}
            />
          </a>
          <a  href={"https://www.energyfoundry.com/"} target="_blank" style={{display:'flex', justifyContent:'center', width:'100%'}}>
            <img
              src={"/assets/Energy Foundry.png"}
              style={{ maxWidth: window.innerHeight < 1000 ? "55%" : "90%", minWidth:'125px'  }}
            />
          </a>
          <a  href={"https://masaimpact.com/"} target="_blank" style={{display:'flex', justifyContent:'center', width:'100%'}}>
            <img
              src={"/assets/Masa Partners.png"}
              style={{ maxWidth: window.innerHeight < 1000 ? "55%" : "90%", minWidth:'125px'  }}
            />
          </a>

         
      {/* {logoArr.map((logo, i) => {
        return (
          <a key={i} href={logo.website} target="_blank" style={{display:'flex', justifyContent:'center', width:'100%'}}>
            <img
              src={logo.img_url}
              style={{ maxWidth: window.innerHeight < 1000 ? "55%" : "90%", minWidth:'125px'  }}
            />
          </a>
        );
      })} */}
    </div>
  );
};
