import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import { useState } from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Marquee from "react-fast-marquee";

const getUrl = window.location;
const baseUrl = getUrl.protocol + "//" + getUrl.host;

const pages = [
  { name: "Home", link: `/` },
  { name: "Schedule", link: `/schedule` },
  { name: "Sponsors", link: `/sponsors` },
  { name: "Speakers", link: `/speakers` },
  // { name: "Elevator Pitch", link: `/elevator` },
  { name: "Presenters", link: `/presenters` },
];
const settings = ["Profile", "Account", "Dashboard", "Logout"];

console.log(baseUrl);

export const InvestorNavBar = () => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [chevron, setChevron] = useState(0);
  const [chevron2, setChevron2] = useState(0);
  const [chevron3, setChevron3] = useState(0);
  const [chevron4, setChevron4] = useState(0);
  const [margin1, setMargin1] = useState("0vw");
  const [margin2, setMargin2] = useState("0vw");
  const [margin3, setMargin3] = useState("0vw");
  const [margin4, setMargin4] = useState("0vw");

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <>
      {" "}
      <AppBar
        position="sticky"
        sx={{
          backgroundColor: "#FBF1FF",
          color: "black !important",
          m: 0,
          width: "100%",
          fontFamily: "Poppins, sans-serif !important",
        }}
      >
        <Container maxWidth="xl" sx={{ margin: "auto" }}>
          <Toolbar disableGutters={true}>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{
                mr: 2,
                flex: 1,
                fontFamily: "Poppins, sans-serif !important",
                display: { xs: "none", md: "flex" },
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <a href="https://venturesummit.tv">
                  <img
                    src="/assets/VSDC black 1.png"
                    style={{ objectFit: "scale-down", maxWidth: "225px" }}
                  />
                </a>
                <div
                  style={{
                    borderLeft: "1px solid black",
                    marginRight: "5px",
                    height: "80%",
                  }}
                ></div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <h5
                    style={{
                      fontFamily: "Poppins, sans-serif !important",
                      fontSize: ".8rem",
                      marginBottom: "0 !important",
                    }}
                  >
                    March 29-31, 2022
                  </h5>
                  <h5
                    style={{ fontSize: ".85rem", marginBottom: "0 !important" }}
                  >
                    Online
                  </h5>
                </div>
              </div>
            </Typography>

            <Box
              sx={{ flex: 1, flexGrow: 1, display: { xs: "flex", md: "none" } }}
            >
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {pages.map((page) => (
                  <a href={page.link}>
                    <MenuItem
                      key={page.name}
                      onClick={handleCloseNavMenu}
                      sx={{ backgroundColor: "#FBF1FF !important" }}
                    >
                      <Typography
                        textAlign="center"
                        sx={{
                          fontFamily: "Poppins, sans-serif !important",
                          color: "black",
                        }}
                      >
                        {page.name}
                      </Typography>
                    </MenuItem>
                  </a>
                ))}
                {/* <a style={{ width: "100%" }}>
                  <MenuItem sx={{ backgroundColor: "#FBF1FF !important" }}>
                    <Button className="dark-btn" sx={{borderRadius:'0px', backgroundColor:'#7C2387', fontFamily: 'Poppins, sans-serif !important', "&:hover":{backgroundColor:'#7C2387'}}} variant="contained">Register</Button>
                    <button
                      onMouseOver={(e) => {
                        setChevron3(1);
                        setMargin3("1.5vw");
                        console.log(chevron);
                      }}
                      onMouseLeave={() => {
                        setChevron3(0);
                        setMargin3("0vw");
                      }}
                      className="dark-btn"
                      style={{
                        marginRight: "1rem",
                        fontWeight: "900",
                        fontSize: "18px",
                        width: "100%",
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <ChevronRightIcon sx={{ opacity: "0" }} />
                        <div>networking lounge</div>
                        <ChevronRightIcon
                          sx={{
                            transition: ".3s all ease-in-out",
                            opacity: chevron3,
                            marginLeft: margin3,
                          }}
                        />
                      </div>
                    </button>
                  </MenuItem>
                </a> */}
                <a
                  style={{ width: "100%" }}
                  href="https://matchmaking.venturesummit.tv/"
                  target="_blank"
                >
                  <MenuItem sx={{ backgroundColor: "#FBF1FF !important" }}>
                    {/* <Button className="dark-btn" sx={{borderRadius:'0px', backgroundColor:'#7C2387', fontFamily: 'Poppins, sans-serif !important', "&:hover":{backgroundColor:'#7C2387'}}} variant="contained">Register</Button> */}
                    <button
                      onMouseOver={(e) => {
                        setChevron4(1);
                        setMargin4("1.5vw");
                        console.log(chevron);
                      }}
                      onMouseLeave={() => {
                        setChevron4(0);
                        setMargin4("0vw");
                      }}
                      className="dark-btn"
                      style={{
                        marginRight: "1rem",
                        fontWeight: "900",
                        fontSize: "18px",
                        width: "100%",
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <ChevronRightIcon sx={{ opacity: "0" }} />
                        <div>matchmaking app</div>{" "}
                        <ChevronRightIcon
                          sx={{
                            transition: ".3s all ease-in-out",
                            opacity: chevron4,
                            marginLeft: margin4,
                          }}
                        />
                      </div>
                    </button>
                  </MenuItem>
                </a>
              </Menu>
            </Box>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{
                flexGrow: 1,
                display: {
                  xs: "flex",
                  md: "none",
                  fontFamily: "Poppins, sans-serif !important",
                },
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <a href="https://venturesummit.tv" target="_blank">
                  <img
                    src="/assets/VSDC black 1.png"
                    style={{ objectFit: "scale-down", maxWidth: "170px" }}
                  />
                </a>
                <div
                  style={{
                    borderLeft: "1px solid white",
                    marginRight: "15px",
                    height: "80%",
                  }}
                ></div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <h5
                    style={{
                      fontFamily: "Poppins, sans-serif !important",
                      fontSize: ".65rem",
                      margin: "0 !important",
                    }}
                  >
                    March 29-31, 2022
                  </h5>
                  <h5 style={{ fontSize: ".65rem", margin: "0 !important" }}>
                    Online
                  </h5>
                </div>
              </div>
            </Typography>
            <Box
              sx={{
                // flex: 1,
                width: "fit-content",
                display: { xs: "none", md: "flex", justifyContent: "left" },
              }}
            >
              {pages.map((page, i) => (
                <a key={i} href={page.link}>
                  <Button
                    key={page.name}
                    onClick={handleCloseNavMenu}
                    sx={{
                      mr: 2,
                      color:
                        window.location.href == page.link ? "#B714E9" : "black",
                      display: "block",
                      textTransform:
                        window.location.href == page.link
                          ? "uppercase"
                          : "capitalize",
                      fontFamily: "Poppins, sans-serif !important",
                      border:
                        page.name == "matchmaking" ? "1px solid #B714E9" : "",
                      borderRadius: page.name == "matchmaking" && "15px",
                      "&:hover": { color: "purple" },
                    }}
                  >
                    {page.name}
                  </Button>
                </a>
              ))}
            </Box>

            <Box
              sx={{ flex: 1, display: window.innerWidth < 1000 ? "none" : "" }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  justifyContent: "right",
                }}
              >
                {/* <Tooltip title="Links will be active at the start of the summit"> */}
                  {/* <a href="https://www.airmeet.com/e/27f73f00-b02b-11ec-996e-dbbf4375de2a">
                    <Button
                      sx={{
                        backgroundColor: "#7C2387 !important",
                        color: "white",
                        borderRadius: "15px",
                        textTransform: "capitalize",
                        fontFamily: "Poppins, sans-serif !important",
                      }}
                      variant="contained"
                    >
                      Networking Lounge
                    </Button>
                  </a> */}
                {/* </Tooltip> */}
                <a href="https://matchmaking.venturesummit.tv/" target="_blank">
                  <Button
                    sx={{
                      backgroundColor: "#7C2387 !important",
                      color: "white",
                      borderRadius: "15px",
                      textTransform: "capitalize",
                      fontFamily: "Poppins, sans-serif !important",
                    }}
                    variant="contained"
                  >
                    Matchmaking App
                  </Button>
                </a>
              </div>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem key={setting} onClick={handleCloseUserMenu}>
                    <Typography textAlign="center">{setting}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <div style={{backgroundColor:'purple', position:'absolute', width:'100%'}}>
        <Marquee style={{backgroundColor:'purple', color:'white', padding: '.5%', overflow:'hidden'}}><h5>SalesQualia - Start Up, Ramp Up, Scale Up: More Customers, More Revenue. <a href="https://salesqualia.com/youngstartupventures/" style={{color: "white"}}>Click here to download a free book</a></h5></Marquee>
      </div>
    </>
  );
};
