import { useEffect, useState } from "react";

import LinkedInIcon from "@mui/icons-material/LinkedIn";
import Skeleton from "@mui/material/Skeleton";
import Paper from "@mui/material/Paper";


export const SponsorCards = () => {
  const [showSponsors, setShowSponsors] = useState("none");
  const [loading, setLoading] = useState(true)

  useEffect(()=>{
    setTimeout(()=>{
      setShowSponsors("");
      setLoading(false)
    }, 3000)
  }, [])

  return (
    <>
      <div className="web-sponsor-view" style={{ marginBottom: "2.5vh", padding: "5% 10%", height:'85vh', overflowY: 'scroll', backgroundColor:'white' }}>
        <div
          className="container sponsor-card"
          style={{ fontFamily: "Roboto Condensed, sans-serif !important" }}
        >
          <h1
            style={{
              textAlign: "center",
              fontWeight: "900",
              color: "black",
            }}
          >
            THANK YOU TO OUR SPONSORS
          </h1>
          <div style={{display: loading ? "flex" : "none", flexDirection:'column', justifyContent:'center'}}>
          <div style={{margin:'auto'}}>{[...Array(50)].map((item, i)=>(
                  <Skeleton
                    sx={{ borderRadius:'15px', margin: loading && "15px", height:'90%'}}
                    variant="rectangular"
                    key={i}
                    width={800}
                    height={300}
                  ></Skeleton>))}</div>
          </div>
          <div style={{display: showSponsors}}>
       
            <Paper
              className="container sponsor-card"
              style={{
                padding: "1rem",
                backgroundColor: "white",
                borderRadius: "10px",
                marginTop: "5vh",
                display:'flex'
              }}
              elevation={15}
            >
              <div style={{ height: "fit-content" }}>
              <div style={{display:'flex', justifyContent:'center', padding:'1.5%', gap:'5%'}}>
                  <h2>Diamond Sponsor</h2>
                  <img
                          style={{ width:'25%', objectFit:'scale-down' }}
                          src="https://youngstartup.com/assets/media/brown_rudnick.webp"
                        />
                  </div>
                <div style={{ height: "50%", display:'flex'}}>
                  
                  <div
                    style={{ paddingTop: "2vh", position: "relative" }}
                  >
                    {/* <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "15%",
                        fontFamily: "Roboto Condensed , sans-serif !important",
                        display:'flex',
                        alignItems:'center',
                        flexDirection:'column'
                      }}
                    >
                      <div style={{ fontSize: "1.25rem" }}>Diamond Sponsor</div>
                      <a href="https://brownrudnick.com/">
                        <img
                          style={{ width:'175%' }}
                          src="https://youngstartup.com/assets/media/brown_rudnick.webp"
                        />
                      </a>
                    </div> */}
                  </div>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "repeat(3, 1fr)",
                      gridTemplateRows: "repeat(2, 1fr)",
                      width:'80%',
                      justifyContent: "center",
                      flexWrap: "wrap",
                      alignItems: "center",
                      margin:'auto'
                    }}
                  >
                    <div
                      style={{
                        textAlign: "center",
                        fontFamily: "Roboto Condensed, sans-serif !important",
                      }}
                    >
                      <img
                        style={{
                          borderRadius: "100%",
                          width: "60%",
                          height: "85%",
                          boxShadow: "0px 0px 25px 10px #B1B1B1",
                        }}
                        src="https://youngstartup.com/assets/media/Dave-Sorin_3265-Crop-Print.png"
                      />
                      <div style={{ fontSize: "1.1rem" }}>
                        David Sorin,{" "}
                        <i style={{ fontSize: ".85rem" }}>Partner</i>{" "}
                        <a href="https://www.linkedin.com/in/david-sorin-6674898/" target="_blank">
                          <LinkedInIcon />
                        </a>
                      </div>
                    </div>
                    <div
                      style={{
                        textAlign: "center",
                        fontFamily: "Roboto Condensed , sans-serif !important",
                      }}
                    >
                      <img
                        style={{
                          borderRadius: "100%",
                          width: "60%",
                          height: "85%",
                          boxShadow: "0px 0px 25px 10px #B1B1B1",
                        }}
                        src="https://youngstartup.com/assets/media/Jared-Sorin_3195-Crop-Print.png"
                      />
                      <div style={{ fontSize: "1.1rem" }}>
                        Jared Sorin,{" "}
                        <i style={{ fontSize: ".85rem" }}>Partner</i>{" "}
                        <a href="https://www.linkedin.com/in/jaredsorin/" target="_blank">
                          <LinkedInIcon />
                        </a>
                      </div>
                    </div>
                    <div
                      style={{
                        textAlign: "center",
                        fontFamily: "Roboto Condensed, sans-serif !important",
                      }}
                    >
                      <img
                        style={{
                          borderRadius: "100%",
                          width: "60%",
                          height: "85%",
                          boxShadow: "0px 0px 25px 10px #B1B1B1",
                        }}
                        src="https://youngstartup.com/assets/media/Matt-Uretsky_3348-Crop-Print.png"
                      />
                      <div style={{ fontSize: "1.1rem" }}>
                        Matthew Uretsky,{" "}
                        <i style={{ fontSize: ".85rem" }}>Partner</i>{" "}
                        <a href="https://www.linkedin.com/in/matthew-uretsky-b767883b/" target="_blank">
                          <LinkedInIcon />
                        </a>
                      </div>
                    </div>

                    <div
                      style={{
                        textAlign: "center",
                        fontFamily: "Roboto Condensed, sans-serif !important",
                      }}
                    >
                      <img
                        style={{
                          borderRadius: "100%",
                          width: "60%",
                          height: "85%",
                          boxShadow: "0px 0px 25px 10px #B1B1B1",
                          marginTop: "2vh",
                        }}
                        src="https://youngstartup.com/assets/media/Joseph-Ferino_3468-Crop-Print.png"
                      />
                      <div style={{ fontSize: "1.1rem" }}>
                        Joseph Ferino,{" "}
                        <i style={{ fontSize: ".85rem" }}>Partner</i>{" "}
                        <a href="https://www.linkedin.com/in/joseph-ferino-1980218/" target="_blank">
                          <LinkedInIcon />
                        </a>
                      </div>
                    </div>
                    <div
                      style={{
                        textAlign: "center",
                        fontFamily: "Roboto Condensed, sans-serif !important",
                      }}
                    >
                      <img
                        style={{
                          borderRadius: "100%",
                          width: "60%",
                          height: "85%",
                          boxShadow: "0px 0px 25px 10px #B1B1B1",
                          marginTop: "2vh",
                        }}
                        src="https://youngstartup.com/assets/media/Cohen_Michael%20NEW.png"
                      />
                      <div style={{ fontSize: "1.1rem" }}>
                        Michael Cohen,{" "}
                        <i style={{ fontSize: ".85rem" }}>Partner</i>{" "}
                        <a href="https://www.linkedin.com/in/mcohenesq/" target="_blank">
                          <LinkedInIcon />
                        </a>
                      </div>
                    </div>
                    <div
                      style={{
                        textAlign: "center",
                        fontFamily: "Roboto Condensed, sans-serif !important",
                      }}
                    >
                      <img
                        style={{
                          borderRadius: "100%",
                          width: "60%",
                          height: "85%",
                          boxShadow: "0px 0px 25px 10px #B1B1B1",
                          marginTop: "2vh",
                        }}
                        src="https://youngstartup.com/assets/media/Carter,%20Alvin.png"
                      />
                      <div style={{ fontSize: "1.1rem" }}>
                        Alvin B. Carter III,{" "}
                        <i style={{ fontSize: ".85rem" }}>Associate</i>{" "}
                        <a href="https://www.linkedin.com/in/alvincarter3/" target="_blank">
                          <LinkedInIcon />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="row"
                  style={{ height: "50%", justifyContent: "center" }}
                >
                  <div
                    style={{
                      textAlign: "center",
                      width:'80%',
                      margin:'3% auto 0% auto',
                      fontSize: "1.15rem",
                      fontFamily: "Roboto Condensed, sans-serif",
                    }}
                  >
                    <div>
                      Brown Rudnick is an international law firm with offices in
                      key financial centers across the United States and Europe,
                      and lawyers serving clients around the globe. We are
                      recognized for our market-leading practices covering
                      several targeted industries, including technology, life
                      sciences, corporate finance, strategic capital, distressed
                      debt, intellectual property, real estate, M&A, bankruptcy
                      and corporate restructuring, litigation and arbitration,
                      white-collar defense, investigations and compliance, civil
                      fraud, special situations, and cross-border deals and
                      disputes.
                    </div>
                    <br />
                    {/* <a
                      href="https://www.brownrudnick.com"
                      target="_blank"
                      style={{ paddingRight: "75px" }}
                    >
                      www.brownrudnick.com
                    </a> */}
                  </div>
                </div>
              </div>
            </Paper>
            <Paper
              className="container sponsor-card"
              style={{
                padding: "2rem",
                backgroundColor: "white",
                borderRadius: "10px",
                marginTop: "5vh",
              }}
              elevation={15}
            >
              <div style={{ height: "fit-content", justifyContent:'center' }}>
                <div style={{display:'flex', width: '100%', justifyContent:'center', alignItems:'center', padding:"1%"}}>
                  <h2>Diamond Sponsor</h2>
              <img
                style={{ maxWidth: "200%"}}
                src="https://youngstartup.com/assets/media/Palladium%20Capital.png"
                        />
                        </div>
                <div  style={{ height: "50%", display:'flex'}}>
                  <div
                    style={{ paddingTop: "2vh", position: "relative" }}
                  >
                    
                    {/* <div
                      style={{
                        position: "absolute",
                        bottom: "5%",
                        left: "15%",
                        fontFamily: "Roboto Condensed , sans-serif !important",
                        display:'flex',
                        flex:1,
                        alignItems:'center',
                        flexDirection:'column'
                      }}
                    >
                      <span style={{ fontSize: "1.25rem" }}>
                        Title Sponsor
                      </span>

                      <a href="https://www.mwe.com/">
                        <img
                          style={{ maxWidth: "200%" }}
                          src="https://youngstartup.com/assets/media/Palladium%20Capital.png"
                        />
                      </a>
                    </div> */}
                  </div>
                  <div>
                  <div
                    style={{
                      display: "grid", 
                      justifyContent: "center !important",
                      width:'80%',
                      gridTemplateColumns:'repeat(3, 1fr)',
                      alignItems: "center",
                      margin:'auto'
                    }}
                  >
                    <div
                      style={{
                        textAlign: "center",
                        fontFamily: "Roboto Condensed, sans-serif !important",
                      }}
                    >
                      <img
                        style={{
                          borderRadius: "100%",
                          width: "60%",
                          height: "85%",
                          boxShadow: "0px 0px 25px 10px #B1B1B1",
                        }}
                        src="https://youngstartup.com/assets/media/joel%20padowitz.png"
                      />
                      <div style={{ fontSize: "1.1rem" }}>
                        Joel Padowitz,{" "}
                        <i style={{ fontSize: ".85rem" }}>Executive</i>{" "}
                        <a href="https://www.linkedin.com/in/joel-padowitz-mba-cfa-95b58b11/" target="_blank">
                          <LinkedInIcon />
                        </a>
                      </div>
                    </div>
                    <div
                      style={{
                        textAlign: "center",
                        fontFamily: "Roboto Condensed , sans-serif !important",
                      }}
                    >
                      <img
                        style={{
                          borderRadius: "100%",
                          width: "60%",
                          height: "85%",
                          boxShadow: "0px 0px 25px 10px #B1B1B1",
                        }}
                        src="https://youngstartup.com/assets/media/Alfonso%20Alejo.png"
                      />
                      <div style={{ fontSize: "1.1rem" }}>
                      Alfonso Alejo,{" "}
                        <i style={{ fontSize: ".85rem" }}>Chairman</i>{" "}
                        <a href="https://www.linkedin.com/in/alfonso-alejo-27415b3/" target="_blank">
                          <LinkedInIcon />
                        </a>
                      </div>
                    </div>
                    <div
                      style={{
                        textAlign: "center",
                        fontFamily: "Roboto Condensed, sans-serif !important",
                      }}
                    >
                      <img
                        style={{
                          borderRadius: "100%",
                          width: "60%",
                          height: "85%",
                          boxShadow: "0px 0px 25px 10px #B1B1B1",
                        }}
                        src="https://youngstartup.com/assets/media/Marc%20Wiersum.png"
                      />
                      <div style={{ fontSize: "1.1rem" }}>
                      Marc Wiersum,{" "}
                        <i style={{ fontSize: ".85rem" }}>Partner</i>{" "}
                        <a href="https://www.linkedin.com/in/marcwiersum/" target="_blank">
                          <LinkedInIcon />
                        </a>
                      </div>
                    </div>

                  </div>
                  <div style={{display:'grid', gridTemplateColumns:'repeat(4, 1fr)',gridColumnGap: "25px", width:'80%',margin:'auto'}}>
                  <div></div>
                  <div
                      style={{
                        textAlign: "center",
                        fontFamily: "Roboto Condensed, sans-serif !important",
                      }}
                    >
                      <img
                        style={{
                          borderRadius: "100%",
                          width: "85%",
                          boxShadow: "0px 0px 25px 10px #B1B1B1",
                          marginTop: "2vh",
                        }}
                        src="https://youngstartup.com/assets/media/Byron%20Haney.png"
                      />
                      <div style={{ fontSize: "1.1rem" }}>
                      Byron Haney,{" "}
                        <i style={{ fontSize: ".85rem" }}>Partner</i>{" "}
                        <a href="https://www.linkedin.com/in/byron-haney/" target="_blank">
                          <LinkedInIcon />
                        </a>
                      </div>
                    </div>
                    
                    <div
                      style={{
                        textAlign: "center",
                        fontFamily: "Roboto Condensed, sans-serif !important",
                      }}
                    >
                      <img
                        style={{
                          borderRadius: "100%",
                          width: "85%",
                          boxShadow: "0px 0px 25px 10px #B1B1B1",
                          marginTop: "2vh",
                        }}
                        src="https://youngstartup.com/assets/media/Elaine%20Pang.png"
                      />
                      <div style={{ fontSize: "1.1rem" }}>
                      Elaine Pang,{" "}
                        <i style={{ fontSize: ".85rem" }}>Partner</i>{" "}
                        <a href="https://www.linkedin.com/in/elaine-pang-365729/">
                          <LinkedInIcon />
                        </a>
                      </div>
                      <div></div>
                    </div>
                  </div>
                  </div>
                </div>
                <div
                  className="row"
                  style={{ height: "50%", justifyContent: "center" }}
                >
                  <div
                    style={{
                      textAlign: "center",
                      width:'80%',
                      margin:'3% auto 0% auto',
                      fontSize: "1.15rem",
                      fontFamily: "Roboto Condensed, sans-serif",
                    }}
                  >
                   Palladium Capital Group is a leading microcap investment bank specializing in PIPEs, private equity, venture capital, reverse mergers, commercial real estate, and the secondary placement of private equity and investment fund interests. Since its founding in 2004, the firm has facilitated hundreds of transactions totaling billions of dollars, and today is represented by more than twenty bankers and brokers throughout the United States.
                  </div>
                </div>
              </div>
            </Paper>
            {/* <Paper
              className="container sponsor-card"
              style={{
                padding: "3rem",
                backgroundColor: "white",
                borderRadius: "10px",
                marginTop: "5vh",
              }}
            >
              <div style={{ height: "fit-content" }}>
                <div className="row" style={{ height: "50%" }}>
                  <div className="col-md-3" style={{ paddingTop: "2vh" , position:'relative'}}>
                    <div
                      style={{
                        position: "absolute",
                        bottom: "%",
                        left: "15%",
                        fontFamily: "Roboto Condensed, sans-serif !important",
                      }}
                    >
                      <span style={{ fontSize: "1.25rem" }}>
                        Diamond Sponsor
                      </span>

                      <a href="https://www.burnslev.com/">
                        <img
                          style={{maxWidth:"120%", marginLeft:"-3rem", padding:"2rem"}}
                          src="https://youngstartup.com/assets/events/vsvc21/sponsors/Burns-Levinson-LLP.png"
                        />
                      </a>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flex: ".65",
                      justifyContent: "center",
                      flexWrap: "wrap",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        textAlign: "center",
                        fontFamily: "Roboto Condensed, sans-serif !important",
                      }}
                    >
                      <img
                        style={{
                          borderRadius: "100%",
                          width: "85%",
                          height: "75%",
                        }}
                        src="https://youngstartup.com/assets/events/vsvc21/sponsors/joseph-volman-e1638277236911.jpeg"
                      />
                      <div style={{ fontSize: "1.1rem", marginTop: ".5rem" }}>
                        Joe Volman,{" "}
                        <i style={{ fontSize: ".85rem" }}>Co-Chair</i>{" "}
                        <a href="https://www.linkedin.com/in/josefvolman/">
                         <LinkedInIcon />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="row"
                  style={{ height: "50%", justifyContent: "center" }}
                >
                  <div
                    style={{
                      textAlign: "center",
                      width: "80%",
                      marginTop: "2vh",
                      fontSize: "1.15rem",
                      fontFamily: "Roboto Condensed, sans-serif",
                    }}
                  >
                    At Burns & Levinson, we practice law differently. While our
                    attorneys agree that results drive our business, building
                    relationships with our clients and providing value added
                    service is the key to our success. We work hard to provide
                    the legal experience and industry knowledge you need, as
                    well as the personal attention you deserve - adding value to
                    your life and the life of your business. Your personal and
                    business success is our top priority. So whatever your legal
                    need, consider it done.
                  </div>
                </div>
              </div>
            </Paper>
            <Paper
              className="container sponsor-card"
              style={{
                padding: "1rem",
                backgroundColor: "white",
                borderRadius: "10px",
                marginTop: "5vh",
              }}
            >
              <div style={{ height: "fit-content" }}>
                <div className="row" style={{ height: "50%" }}>
                  <div className="col-md-3" style={{ paddingTop: "2vh" , position:'relative'}}>
                    <div style={{position:"absolute", bottom:"5%", left:"15%", fontFamily: "Roboto Condensed , sans-serif !important"}}>
                      <span style={{fontSize:"1.25rem"}}>Platinum Sponsor</span>

                      <a href="https://www.oracle.com/startup/">
                        <img
                          style={{maxWidth:"120%", marginLeft:"0rem"}}
                          src="https://youngstartup.com/assets/events/vsvc21/sponsors/Oracle_forStartups.png"
                        />
                      </a>
                    </div>
                  </div>
                  <div style={{display:"flex", flex:"1", justifyContent:"center", flexWrap:"wrap"}}>
                    <div style={{textAlign:"center", fontFamily: "Roboto Condensed, sans-serif !important"}}>
                      <img
                        style={{borderRadius:"100%", width:"65%", height:"85%"}}
                        src="https://youngstartup.com/assets/events/vsvc21/sponsors/ezgif.com-gif-maker.webp"
                      />
                      <div style={{fontSize:"1.1rem"}}>
                        Kenan Kitchen,{" "}
                        <i style={{fontSize:".85rem"}}>VC Manager</i> {" "}
                        <a href="https://www.linkedin.com/in/kenankitchen/">
                          <LinkedInIcon />
                        </a>
                      </div>
                    </div>
                    <div style={{textAlign:"center", fontFamily: "Roboto Condensed , sans-serif !important"}}>
                      <img
                        style={{borderRadius:"100%", width:"65%", height:"85%"}}
                        src="https://youngstartup.com/assets/events/vsvc21/sponsors/perry-light-e1638273461568.jpg"
                      />
                      <div style={{fontSize:"1.1rem"}}>
                        Perry Light, <i style={{fontSize:".85rem"}}>Market Lead</i>{" "}
                        {" "}
                        <a href="https://www.linkedin.com/in/perrylight/">
                         <LinkedInIcon />
                        </a>
                      </div>
                    </div>
                    <div style={{textAlign:"center", fontFamily: "Roboto Condensed, sans-serif !important"}}>
                      <img
                        style={{borderRadius:"100%", width:"65%", height:"85%"}}
                        src="https://youngstartup.com/assets/events/vsvc21/sponsors/carter-geisbush-e1638273609987.jpeg"
                      />
                      <div style={{fontSize:"1.1rem"}}>
                        Carter Geisbush,{" "}
                        <i style={{fontSize:".85rem"}}>Team Lead</i> {" "}
                        <a href="https://www.linkedin.com/in/cartergeisbush/">
                        <LinkedInIcon />
                        </a>
                      </div>
                    </div>
                    <div style={{textAlign:"center", fontFamily: "Roboto Condensed, sans-serif !important"}}>
                      <img
                        style={{borderRadius: "100%", width: "62%", height: "82.5%"}}
                        src="https://youngstartup.com/assets/events/vsvc21/sponsors/franco-lebolo-e1638273693984.jpeg"
                      />
                      <div style={{fontSize:"1.1rem"}}>
                        Franco Lebolo,{" "}
                        <i style={{fontSize:".85rem"}}>Business Manager</i>{" "}
                        <a href="https://www.linkedin.com/in/francolebolo/">
                        <LinkedInIcon />
                        </a>
                      </div>
                    </div>
                    <div style={{textAlign:"center", fontFamily: "Roboto Condensed, sans-serif !important"}}>
                      <img
                        style={{borderRadius:"100%", width:"50%", height:"83.5%"}}
                        src="https://youngstartup.com/assets/events/vsvc21/sponsors/claudia-fajkarz-e1638273809544.jpeg"
                      />
                      <div style={{fontSize:"1.1rem", marginLeft:"1rem"}}>
                        {" "}
                        Claudia Fajkarz,{" "}
                        <i style={{fontSize:".85rem"}}>
                          Business Development
                        </i>{" "}
                        {" "}
                        <a href="https://www.linkedin.com/in/claudiafaj/">
                        <LinkedInIcon />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="row"
                  style={{ height: "50%", justifyContent: "center" }}
                >
                  <div style={{textAlign:"center", width:"80%", marginTop:"5vh", fontSize:"1.15rem", fontFamily: "Roboto Condensed, sans-serif"}}>
                    Oracle Corporation (NYSE: ORCL) is a computer technology
                    company headquartered in Austin, Texas. Oracle offers
                    integrated suites of applications plus secure, autonomous
                    infrastructure in the Oracle Cloud. With 2020 revenue over
                    39 billion, Oracle was ranked 80 in the Forbes 500 list of
                    largest US companies.
                  </div>
                </div>
              </div>
            </Paper> */}
            <Paper
              className="container sponsor-card"
              style={{
                padding: "2rem",
                backgroundColor: "white",
                borderRadius: "10px",
                marginTop: "5vh",
              }}
              elevation={15}
            >
              <div style={{ height: "fit-content" }}>
              <div style={{display:'flex', justifyContent:'center', padding:'1%', gap:'5%', alignItems:'center'}}>
                  <h2>Platinum Sponsor</h2>
                  <img
                          style={{ maxWidth: "200%" }}
                          src="https://youngstartup.com/assets/events/vsvc21/sponsors/McDermott-Will-Emery-LLP_2021.png"
                        />
                  </div>
                <div  style={{ height: "50%", display:'flex', width:'100%', justifyContent:'center' }}>
                  <div
                    style={{ paddingTop: "2vh", position: "relative" }}
                  >
                    {/* <div
                      style={{
                        position: "absolute",
                        bottom: "5%",
                        left: "15%",
                        fontFamily: "Roboto Condensed , sans-serif !important",
                        display:'flex',
                        flex:1,
                        alignItems:'center',
                        flexDirection:'column'
                      }}
                    >
                      <span style={{ fontSize: "1.25rem" }}>
                        Platinum Sponsor
                      </span>

                      <a href="https://www.mwe.com/">
                        <img
                          style={{ maxWidth: "200%" }}
                          src="https://youngstartup.com/assets/events/vsvc21/sponsors/McDermott-Will-Emery-LLP_2021.png"
                        />
                      </a>
                    </div> */}
                  </div>
                  <div>
                  <div
                    style={{
                      display: "grid", 
                      justifyContent: "center !important",
                      width:'80%',
                      height:'50%',
                      gridTemplateColumns:'repeat(3, 1fr)',
                      alignItems: "center",
                      margin:'auto'
                    }}
                  >
                    <div
                      style={{
                        textAlign: "center",
                        fontFamily: "Roboto Condensed, sans-serif !important",
                      }}
                    >
                      <img
                        style={{
                          borderRadius: "100%",
                          width: "65%",
                          height: "85%",
                          boxShadow: "0px 0px 25px 10px #B1B1B1",
                        }}
                        src="https://youngstartup.com/assets/events/vsvc21/sponsors/angus-mcquilken-e1638275277458.jpg"
                      />
                      <div style={{ fontSize: "1.1rem" }}>
                        Angus McQuilken,{" "}
                        <i style={{ fontSize: ".85rem" }}>Executive</i>{" "}
                        <a href="https://www.linkedin.com/in/angus-mcquilken-a682326/" target="_blank">
                          <LinkedInIcon />
                        </a>
                      </div>
                    </div>
                    <div
                      style={{
                        textAlign: "center",
                        fontFamily: "Roboto Condensed , sans-serif !important",
                      }}
                    >
                      <img
                        style={{
                          borderRadius: "100%",
                          width: "65%",
                          height: "85%",
                          boxShadow: "0px 0px 25px 10px #B1B1B1",
                        }}
                        src="https://youngstartup.com/assets/events/vsvc21/sponsors/byron-kalogerou-e1638275355958.jpg"
                      />
                      <div style={{ fontSize: "1.1rem" }}>
                        Byron Kalogerou,{" "}
                        <i style={{ fontSize: ".85rem" }}>Chairman</i>{" "}
                        <a href="https://www.linkedin.com/in/byron-kalogerou-5893582/" target="_blank">
                          <LinkedInIcon />
                        </a>
                      </div>
                    </div>
                    <div
                      style={{
                        textAlign: "center",
                        fontFamily: "Roboto Condensed, sans-serif !important",
                      }}
                    >
                      <img
                        style={{
                          borderRadius: "100%",
                          width: "65%",
                          height: "85%",
                          boxShadow: "0px 0px 25px 10px #B1B1B1",
                        }}
                        src="https://youngstartup.com/assets/events/vsvc21/sponsors/brian-gordon-e1638275459582.jpg"
                      />
                      <div style={{ fontSize: "1.1rem" }}>
                        Brian Gordon,{" "}
                        <i style={{ fontSize: ".85rem" }}>Partner</i>{" "}
                        <a href="https://www.linkedin.com/in/brian-gordon-475a652/" target="_blank">
                          <LinkedInIcon />
                        </a>
                      </div>
                    </div>

                  </div>
                  <div style={{display:'grid', gridTemplateColumns:'repeat(4, 1fr)',gridColumnGap: "50px", width: '80%', margin:'auto', height:'50%',}}>
                  <div></div>
                  <div
                      style={{
                        textAlign: "center",
                        fontFamily: "Roboto Condensed, sans-serif !important",
                      }}
                    >
                      <img
                        style={{
                          borderRadius: "100%",
                          width: "100%",
                          boxShadow: "0px 0px 25px 10px #B1B1B1",
                          marginTop: "2vh",
                        }}
                        src="https://youngstartup.com/assets/events/vsvc21/sponsors/mark-mihanovic-e1638275550487.jpg"
                      />
                      <div style={{ fontSize: "1.1rem" }}>
                        Mark Mihanovic,{" "}
                        <i style={{ fontSize: ".85rem" }}>Partner</i>{" "}
                        <a href="https://www.linkedin.com/in/mark-mihanovic-5927b66/" target="_blank">
                          <LinkedInIcon />
                        </a>
                      </div>
                    </div>
                    
                    <div
                      style={{
                        textAlign: "center",
                        fontFamily: "Roboto Condensed, sans-serif !important",
                      }}
                    >
                      <img
                        style={{
                          borderRadius: "100%",
                          width: "100%",
                          boxShadow: "0px 0px 25px 10px #B1B1B1",
                          marginTop: "2vh",
                        }}
                        src="https://youngstartup.com/assets/events/vsvc21/sponsors/amora-sharma-e1638275631418.jpg"
                      />
                      <div style={{ fontSize: "1.1rem" }}>
                        Aroma Sharma,{" "}
                        <i style={{ fontSize: ".85rem" }}>Partner</i>{" "}
                        <a href="https://www.linkedin.com/in/aromasharma/" target="_blank">
                          <LinkedInIcon />
                        </a>
                      </div>
                      <div></div>
                    </div>
                  </div>
                  </div>
                </div>
                <div
                  className="row"
                  style={{ height: "50%", justifyContent: "center" }}
                >
                  <div
                    style={{
                      textAlign: "center",
                      width:'80%',
                      margin:'3% auto 0% auto',
                      fontSize: "1.15rem",
                      fontFamily: "Roboto Condensed, sans-serif",
                    }}
                  >
                    McDermott's Life Sciences Team will get you closer to your
                    goals. Composed of top lawyers with demonstrated strength
                    across intellectual property, regulatory, transactional and
                    litigation law, we're a purpose-built team of thought
                    leaders united by a passion for our work. Our team can meet
                    the legal needs of life sciences companies at every stage of
                    development. For decades, we have embraced the value of
                    focused knowledge, harnessing both the particular skills of
                    individuals and the collective experience of our team. This
                    makes us uniquely qualified to help you move business
                    initiatives across the finish line when it matters and
                    anticipate what's next.
                  </div>
                </div>
              </div>
            </Paper>
            {/* <Paper
              className="container sponsor-card"
              style={{
                padding: "1rem",
                backgroundColor: "white",
                borderRadius: "10px",
                marginTop: "5vh",
              }}
            >
              <div style={{ height: "fit-content" }}>
                <div className="row" style={{ height: "50%" }}>
                  <div className="col-md-3" style={{ paddingTop: "2vh" , position:'relative'}}>
                    <div style={{position:"absolute", bottom:"5%", left:"15%", fontFamily: "Roboto Condensed , sans-serif !important"}}>
                      <span style={{fontSize:"1.25rem"}}>Coaching Sponsor</span>

                      <a href="https://www.troutman.com/">
                        <img
                          style={{maxWidth:"100%", marginLeft:"-2rem"}}
                          src="https://youngstartup.com/assets/events/vsvc21/sponsors/Troutman-Pepper-LLP.jpeg"
                        />
                      </a>
                    </div>
                  </div>
                  <div style={{display:"flex", flex:"1",justifyContent:"space-around", flexWrap:"wrap",alignItems:"center"}}>
                    <div style={{textAlign:"center", fontFamily: "Roboto Condensed, sans-serif !important"}}>
                      <img
                        style={{borderRadius:"100%", width:"65%", height:"85%"}}
                        src="https://youngstartup.com/assets/events/vsvc21/sponsors/gregory-len-e1638279233893.jpg"
                      />
                      <div style={{fontSize:"1.1rem"}}>
                        Gregory Len, <i style={{fontSize:".85rem"}}>Partner</i>{" "}
                        {" "}
                        <a href="https://www.linkedin.com/in/gregory-len-03312322/">
                           <LinkedInIcon />
                        </a>
                      </div>
                    </div>
                    <div style={{textAlign:"center", fontFamily: "Roboto Condensed , sans-serif !important"}}>
                      <img
                        style={{borderRadius:"100%", width:"65%", height:"85%"}}
                        src="https://youngstartup.com/assets/events/vsvc21/sponsors/steven-london-e1638279440606.jpeg"
                      />
                      <div style={{fontSize:"1.1rem"}}>
                        Steven London, <i style={{fontSize:".85rem"}}>Partner</i>{" "}
                        {" "}
                        <a href="https://www.linkedin.com/in/steven-r-london-b411114/">
                           <LinkedInIcon />
                        </a>
                      </div>
                    </div>
                    <div style={{textAlign:"center", fontFamily: "Roboto Condensed, sans-serif !important"}}>
                      <img
                        style={{borderRadius:"100%", width:"65%", height:"85%"}}
                        src="https://youngstartup.com/assets/events/vsvc21/sponsors/nicholas-stawasz-e1638279506464.jpeg"
                      />
                      <div style={{fontSize:"1.1rem"}}>
                        Nicholas Stawasz,{" "}
                        <i style={{fontSize:".85rem"}}>Partner</i> {" "}
                        <a href="https://linkedin.com/in/nicholasstawasz/">
                           <LinkedInIcon />
                        </a>
                      </div>
                    </div>
                    <div style={{textAlign:"center", fontFamily: "Roboto Condensed, sans-serif !important"}}>
                      <img
                        style={{borderRadius:"100%", width:"53%", height:"62%"}}
                        src="https://youngstartup.com/assets/events/vsvc21/sponsors/brian-dolan-e1638295519871.jpeg"
                      />
                      <div style={{fontSize:"1.1rem"}}>
                        Brian Dolan,{" "}
                        <i style={{fontSize:".85rem"}}>
                          Business Development Manager
                        </i>{" "}
                        {" "}
                        <a href="https://www.linkedin.com/in/brianedolan/">
                           <LinkedInIcon />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="row"
                  style={{ height: "50%", justifyContent: "center" }}
                >
                  <div
                    style={{
                      textAlign: "center",
                      width: "80%",
                      marginTop: "2vh",
                      fontSize: "1.15rem",
                      fontFamily: "Roboto Condensed, sans-serif",
                    }}
                  >
                    Putting clients first — and empowering our attorneys and
                    staff to innovate toward that end — has led to exciting
                    advances, like our proprietary e-discovery service, bespoke
                    technology solutions, and market-leading client feedback
                    program. We’re proud to be among BTI’s 2021 Client Service
                    A-Team, and to earn Law Firm Innovator honors from The Legal
                    Intelligencer for a custom, AI-powered solution for more
                    efficient and equitable project staffing.
                  </div>
                </div>
              </div>
            </Paper>
            <Paper
              className="container sponsor-card"
              style={{
                padding: "1rem",
                backgroundColor: "white",
                borderRadius: "10px",
                marginTop: "5vh",
              }}
            >
              <div style={{ height: "fit-content" }}>
                <div className="row" style={{ height: "50%" }}>
                  <div className="col-md-3" style={{ paddingTop: "2vh" , position:'relative'}}>
                    <div style={{position:"absolute", bottom:"5%", left:"15%", fontFamily: "Roboto Condensed , sans-serif !important"}}>
                      <div style={{fontSize:"1.25rem"}}>
                      Europe Partner
                      </div>

                      <a href="https://www.invest-in-bavaria.com/en/">
                        <img
                          style={{maxWidth:"90%"}}
                          src="https://youngstartup.com/assets/events/vsvc21/sponsors/Invest-in-Bavaria_2019.png"
                        />
                      </a>
                    </div>
                  </div>
                  <div style={{display:"flex",flex:".7",justifyContent:"center", flexWrap:"wrap",alignItems:"center"}}>
                    <div style={{textAlign:"center", fontFamily: "Roboto Condensed, sans-serif !important"}}>
                      <img
                        style={{borderRadius:"100%",width:"65%",height:"85%"}}
                        src="https://youngstartup.com/assets/events/vsvc21/sponsors/jan-sutherland-e1638291767699.jpeg"
                      />
                      <div style={{fontSize:"1.1rem"}}>
                        Jan Sutherland,{" "}
                        <i style={{fontSize:".85rem"}}>Executive Director</i>{" "}
                        {" "}
                        <a href="https://www.linkedin.com/in/jan-danisman-sutherland-5b632518/">
                           <LinkedInIcon />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="row"
                  style={{ height: "50%", justifyContent: "center" }}
                >
                  <div
                    style={{
                      textAlign: "center",
                      width: "80%",
                      marginTop: "2vh",
                      fontSize: "1.15rem",
                      fontFamily: "Roboto Condensed, sans-serif",
                    }}
                  >
                    Invest in Bavaria has been in existence since 1999 and
                    offers companies a wealth of services for all stages of
                    setting up a business in the state, from the planning phase,
                    throughout the search for and selection of a location, to
                    implementation.Even once companies are successfully settled,
                    Invest in Bavaria continues to support them, such as during
                    a planned expansion.
                  </div>
                </div>
              </div>
            </Paper> */}
            {/* <Paper
              className="container sponsor-card"
              style={{
                padding: "1rem",
                backgroundColor: "white",
                borderRadius: "10px",
                marginTop: "5vh",
              }}
            >
              <div style={{ height: "fit-content" }}>
                <div className="row" style={{ height: "50%" }}>
                  <div className="col-md-3" style={{ paddingTop: "2vh" , position:'relative'}}>
                    <div style={{position:"absolute", bottom:"5%", left:"15%", fontFamily: "Roboto Condensed , sans-serif !important"}}>
                      <div style={{fontSize:"1.25rem"}}>Gold Sponsor</div>

                      <a href="https://www.zendesk.com/startups/">
                        <img
                          style={{maxWidth:"50%"}}
                          src="https://youngstartup.com/assets/events/vsvc21/sponsors/Zendesk-startups-logo-kale-transparent-2.png"
                        />
                      </a>
                    </div>
                  </div>
                  <div style={{display:"flex", flex:"1",justifyContent:"space-around", flexWrap:"wrap",alignItems:"center"}}>
                    <div style={{textAlign:"center", fontFamily: "Roboto Condensed, sans-serif !important"}}>
                      <img
                        style={{borderRadius:"100%", width:"55%", height:"85%"}}
                        src="https://youngstartup.com/assets/events/vsvc21/sponsors/adam-odonell-e1638289540122.jpeg"
                      />
                      <div style={{fontSize:"1.1rem"}}>
                        Adam O'Donnell,{" "}
                        <i style={{fontSize:".85rem"}}>
                          VC & Startup Partnerships
                        </i>{" "}
                        
                        <a href="https://www.linkedin.com/in/adamfodonnell/">
                           <LinkedInIcon />
                        </a>
                      </div>
                    </div>
                    <div style={{textAlign:"center", fontFamily: "Roboto Condensed , sans-serif !important"}}>
                      <img
                        style={{borderRadius:"100%",width:"65%",height:"85%"}}
                        src="https://youngstartup.com/assets/events/vsvc21/sponsors/tommy-klouwers-e1638289550974.jpeg"
                      />
                      <div style={{fontSize:"1.1rem"}}>
                        Tommy Klouwers,{" "}
                        <i style={{fontSize:".85rem"}}>Growth Manager</i> {" "}
                        <a href="https://www.linkedin.com/in/tommyklouwers/">
                           <LinkedInIcon />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="row"
                  style={{ height: "50%", justifyContent: "center" }}
                >
                  <div
                    style={{
                      textAlign: "center",
                      width: "80%",
                      marginTop: "2vh",
                      fontSize: "1.15rem",
                      fontFamily: "Roboto Condensed, sans-serif",
                    }}
                  >
                    Zendesk is a service-first CRM company that builds software
                    designed to improve customer relationships.As employees, we
                    encourage each other to grow and innovate.As a company, we
                    roll up our sleeves to plant roots in the communities we
                    call home.Our software is powerful and flexible, and scales
                    to meet the needs of any business.Even yours.
                  </div>
                </div>
              </div>
            </Paper> */}
            <Paper
              className="container sponsor-card"
              style={{
                padding: "1rem",
                backgroundColor: "white",
                borderRadius: "10px",
                marginTop: "5vh",
              }}
              elevation={15}
            >
              <div style={{ height: "fit-content" }}>
              <div style={{display:'flex', justifyContent:'center', padding:'1%', gap:'5%', alignItems:'center'}}>
                  <h2>Gold Sponsor</h2>
                  <a href="https://www.agencyip.com/">
                        <img
                          style={{ maxWidth: "225%", padding: "1rem" }}
                          src="/assets/Agency IP.png"
                        />
                      </a>
                  </div>
                <div className="row" style={{ height: "50%", display:'flex', gap:'25%' }}>

                  {/* <div
                    style={{ paddingTop: "2vh", position: "relative", display:'flex' }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        bottom: "5%",
                        left: "15%",
                        fontFamily: "Roboto Condensed , sans-serif !important",
                      }}
                    >
                      <div style={{ fontSize: "1.25rem" }}>Gold Sponsor</div>

                      <a href="https://www.agencyip.com/">
                        <img
                          style={{ maxWidth: "225%", padding: "1rem" }}
                          src="/assets/Agency IP.png"
                        />
                      </a>
                    </div>
                  </div> */}
                  <div
                    style={{
                      display: "grid",  
                      gridTemplateColumns: "repeat(1, 1fr)",
                      gridTemplatRows: "1fr",
                      flex: ".7",
                      margin:'auto',
                      justifyContent: "center",
                      flexWrap: "wrap",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        textAlign: "center",
                        fontFamily: "Roboto Condensed, sans-serif !important",
                      }}
                    >
                      <img
                        style={{
                          borderRadius: "100%",
                          width: "25%",
                          height: "85%",
                          boxShadow: "0px 0px 25px 10px #B1B1B1",
                        }}
                        src="https://youngstartup.com/assets/media/joshua%201.png"
                      />
                      <div style={{ fontSize: "1.1rem" }}>
                      Joshua Pichinson,{" "}
                        <i style={{ fontSize: ".85rem" }}>Managing Director</i>{" "}
                        <a href="https://www.linkedin.com/in/jpichinson/" target="_blank">
                          <LinkedInIcon />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="row"
                  style={{ height: "50%", justifyContent: "center" }}
                >
                  <div
                    style={{
                      textAlign: "center",
                      width:'80%',
                      margin:'3% auto 0% auto',
                      fontSize: "1.15rem",
                      fontFamily: "Roboto Condensed, sans-serif",
                    }}
                  >
                   AgencyIP assists our clients to identify hidden value and work on developing addition ways to monetize your patent, trademark, and copyright assets. Being industry experts, we enable our clients to fully monetize and enjoy long-term success through agencyIP relationships and strategies.
                  </div>
                </div>
              </div>
            </Paper>
                    <Paper
              className="container sponsor-card"
              style={{
                padding: "1rem",
                backgroundColor: "white",
                borderRadius: "10px",
                marginTop: "5vh",
              }}
              elevation={15}
            >
              <div style={{ height: "fit-content" }}>
              <div style={{display:'flex', justifyContent:'center', padding:'1%', gap:'5%', alignItems:'center'}}>
                  <h2>Gold Sponsor</h2>
                  <img
                          style={{ maxWidth: "125%" }}
                          src="/assets/boardsy.png"
                        />
                  </div>
                <div className="row" style={{ height: "50%", display:'flex',  }}>
          
                  <div
                    style={{
                      display: "grid",  
                      gridTemplateColumns: "repeat(2, 1fr)",
                      gridTemplatRows: "1fr",
                      flex: ".7",
                      margin:'auto',
                      justifyContent: "center",
                      flexWrap: "wrap",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        textAlign: "center",
                        fontFamily: "Roboto Condensed, sans-serif !important",
                      }}
                    >
                      <img
                        style={{
                          borderRadius: "100%",
                          width: "45%",
                          height: "85%",
                          boxShadow: "0px 0px 25px 10px #B1B1B1",
                        }}
                        src="/assets/MartinRowinski030122 1.png"
                      />
                      <div style={{ fontSize: "1.1rem" }}>
                        Martin Rowinski,{" "}
                        <i style={{ fontSize: ".85rem" }}>CEO</i>{" "}
                        <a href="https://www.linkedin.com/in/martinrowinski/" target="_blank">
                          <LinkedInIcon />
                        </a>
                      </div>
                    </div>
                    <div
                      style={{
                        textAlign: "center",
                        fontFamily: "Roboto Condensed, sans-serif !important",
                      }}
                    >
                      <img
                        style={{
                          borderRadius: "100%",
                          width: "45%",
                          height: "85%",
                          boxShadow: "0px 0px 25px 10px #B1B1B1",
                        }}
                        src="/assets/daniel-henry.jpeg"
                      />
                      <div style={{ fontSize: "1.1rem" }}>
                        Daniel Henry,{" "}
                        <i style={{ fontSize: ".85rem" }}>COO</i>{" "}
                        <a href="https://www.linkedin.com/in/daniel-henry-21983340/" target="_blank">
                          <LinkedInIcon />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="row"
                  style={{ height: "50%", justifyContent: "center" }}
                >
                  <div
                    style={{
                      textAlign: "center",
                      width:'80%',
                      margin:'3% auto 0% auto',
                      fontSize: "1.15rem",
                      fontFamily: "Roboto Condensed, sans-serif",
                    }}
                  >
                  Boardsi is a modern recruiting company providing executives with advisory positions and companies with top talent. Through our advanced technology, human connection and private network we help revolutionize businesses and grow careers.
                  </div>
                </div>
              </div>
            </Paper>
            <Paper
              className="container sponsor-card"
              style={{
                padding: "1rem",
                backgroundColor: "white",
                borderRadius: "10px",
                marginTop: "5vh",
              }}
              elevation={15}
            >
              <div style={{ height: "fit-content" }}>
              <div style={{display:'flex', justifyContent:'center', padding:'1%', gap:'5%', alignItems:'center'}}>
                  <h2>Gold Sponsor</h2>
                  <img
                          style={{ maxWidth: "125%" }}
                          src="https://youngstartup.com/assets/events/vsvc21/sponsors/Cartika-250x134.png"
                        />
                  </div>
                <div className="row" style={{ height: "50%", display:'flex',  }}>
                  {/* <div
                    style={{ paddingTop: "2vh", position: "relative", display:'flex', alignItems:'center', }}
                  >
                    <div
                      style={{
                        alignItems:'center',
                        fontFamily: "Roboto Condensed , sans-serif !important",
                      }}
                    >
                      <div style={{ fontSize: "1.25rem" }}>Gold Sponsor</div>

                      <a href="https://www.cartika.com/">
                        <img
                          style={{ maxWidth: "125%" }}
                          src="https://youngstartup.com/assets/events/vsvc21/sponsors/Cartika-250x134.png"
                        />
                      </a>
                    </div>
                  </div> */}
                  <div
                    style={{
                      display: "grid",  
                      gridTemplateColumns: "repeat(1, 1fr)",
                      gridTemplatRows: "1fr",
                      flex: ".7",
                      margin:'auto',
                      justifyContent: "center",
                      flexWrap: "wrap",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        textAlign: "center",
                        fontFamily: "Roboto Condensed, sans-serif !important",
                      }}
                    >
                      <img
                        style={{
                          borderRadius: "100%",
                          width: "25%",
                          height: "85%",
                          boxShadow: "0px 0px 25px 10px #B1B1B1",
                        }}
                        src="https://youngstartup.com/assets/events/vsvc21/sponsors/andrew-rouchotas-e1638289749607.jpeg"
                      />
                      <div style={{ fontSize: "1.1rem" }}>
                        Andrew Rouchotas,{" "}
                        <i style={{ fontSize: ".85rem" }}>CEO</i>{" "}
                        <a href="https://www.linkedin.com/in/andrewrouchotascartikaceo/" target="_blank">
                          <LinkedInIcon />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="row"
                  style={{ height: "50%", justifyContent: "center" }}
                >
                  <div
                    style={{
                      textAlign: "center",
                      width:'80%',
                      margin:'3% auto 0% auto',
                      fontSize: "1.15rem",
                      fontFamily: "Roboto Condensed, sans-serif",
                    }}
                  >
                    We specialize in pro-actively managed public cloud and
                    personalized IaaS infrastructure, including meeting and
                    exceeding compliance standards.Whether you are hosting
                    internal applications, file storage and/or web based
                    applications like Content Management or eCommerce, no matter
                    how complex your infrastructure architecture, Cartika can
                    accommodate your requirements.If you are storing any
                    personal data and require data compliance, you absolutely
                    need to speak with Cartika about our proactive and data
                    compliant Managed Services.
                  </div>
                </div>
              </div>
            </Paper>
            <Paper
              className="container sponsor-card"
              style={{
                padding: "1rem",
                backgroundColor: "white",
                borderRadius: "10px",
                marginTop: "5vh",
              }}
              elevation={15}
            >
              <div style={{ height: "fit-content" }}>
              <div style={{display:'flex', justifyContent:'center', padding:'1%', gap:'5%', alignItems:'center'}}>
                  <h2>Gold Sponsor</h2>
                  <img
                    style={{ maxWidth: "20%", padding: "1rem" }}
                    src="https://youngstartup.com/assets/events/vsvc21/sponsors/fullthrottlefalatoleads.jpeg"
                    />
                  </div>
                <div className="row" style={{ height: "50%", display:'flex', gap:'25%' }}>
                  {/* <div
                    className="col-md-3"
                    style={{ paddingTop: "2vh", position: "relative" }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        bottom: "5%",
                        left: "15%",
                        fontFamily: "Roboto Condensed , sans-serif !important",
                      }}
                    >
                      <div style={{ fontSize: "1.25rem" }}>Gold Sponsor</div>

                      <a href="https://www.linkedin.com/in/mfalato/">
                        <img
                          style={{ maxWidth: "225%", padding: "1rem" }}
                          src="https://youngstartup.com/assets/events/vsvc21/sponsors/fullthrottlefalatoleads.jpeg"
                        />
                      </a>
                    </div>
                  </div> */}
                  <div
                    style={{
                      display: "grid",  
                      gridTemplateColumns: "repeat(1, 1fr)",
                      gridTemplatRows: "1fr",
                      flex: ".7",
                      margin:'auto',
                      justifyContent: "center",
                      flexWrap: "wrap",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        textAlign: "center",
                        fontFamily: "Roboto Condensed, sans-serif !important",
                      }}
                    >
                      <img
                        style={{
                          borderRadius: "100%",
                          width: "25%",
                          height: "85%",
                          boxShadow: "0px 0px 25px 10px #B1B1B1",
                        }}
                        src="https://youngstartup.com/assets/events/vsvc21/sponsors/michael-falato.jpg"
                      />
                      <div style={{ fontSize: "1.1rem" }}>
                        Michael Falato,{" "}
                        <i style={{ fontSize: ".85rem" }}>Founder</i>{" "}
                        <a href="https://www.linkedin.com/in/mfalato/" target="_blank">
                          <LinkedInIcon />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="row"
                  style={{ height: "50%", justifyContent: "center" }}
                >
                  <div
                    style={{
                      textAlign: "center",
                      width:'80%',
                      margin:'3% auto 0% auto',
                      fontSize: "1.15rem",
                      fontFamily: "Roboto Condensed, sans-serif",
                    }}
                  >
                    Full Throttle Falato Leads provides on-demand sales lead
                    generation for business to business clients for less than a
                    full-time sales person’s salary. We have access to over 950
                    million contacts and can develop persona-based drip
                    marketing campaigns using Emails & LinkedIn automation. We
                    will provide a shorter revenue cycle, tighten the messaging,
                    as well as provide metrics, insights, and consultation.
                  </div>
                </div>
              </div>
            </Paper>
            <Paper
              className="container sponsor-card"
              style={{
                padding: "1rem",
                backgroundColor: "white",
                borderRadius: "10px",
                marginTop: "5vh",
              }}
              elevation={15}
            >
              <div style={{ height: "fit-content" }}>
              <div style={{display:'flex', justifyContent:'center', padding:'1%', gap:'5%', alignItems:'center'}}>
                  <h2>Gold Sponsor</h2>
                  <img
                          style={{ maxWidth: "25%", padding: "1rem" }}
                          src="https://youngstartup.com/assets/events/vsvc21/sponsors/IC-logo-2.png"
                        />
                  </div>
                <div className="row" style={{ height: "50%", display:'flex'  }}>
                  <div
                    style={{ paddingTop: "2vh", position: "relative", display:'flex' }}
                  >
                    {/* <div
                      style={{
                        position: "absolute",
                        bottom: "5%",
                        left: "15%",
                        fontFamily: "Roboto Condensed , sans-serif !important",
                      }}
                    >
                      <div style={{ fontSize: "1.25rem" }}>Gold Sponsor</div>

                      <a href="https://innovationcenter.tech/">
                        <img
                          style={{ maxWidth: "225%", padding: "1rem" }}
                          src="https://youngstartup.com/assets/events/vsvc21/sponsors/IC-logo-2.png"
                        />
                      </a>
                    </div> */}
                  </div>
                  <div
                    style={{
                      display: "grid",  
                      gridTemplateColumns: "repeat(1, 1fr)",
                      gridTemplatRows: "1fr",
                      flex: ".7",
                      margin:'auto',
                      justifyContent: "center",
                      flexWrap: "wrap",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        textAlign: "center",
                        fontFamily: "Roboto Condensed, sans-serif !important",
                      }}
                    >
                      <img
                        style={{
                          borderRadius: "100%",
                          width: "25%",
                          height: "85%",
                          boxShadow: "0px 0px 25px 10px #B1B1B1",
                        }}
                        src="https://youngstartup.com/assets/events/vsvc21/sponsors/Iva-Abadjieva.png"
                      />
                      <div style={{ fontSize: "1.1rem" }}>
                        Ivelina Abadjieva,{" "}
                        <i style={{ fontSize: ".85rem" }}>Founder</i>{" "}
                        <a href="https://www.linkedin.com/in/iva-abadjieva-b778b36/?originalSubdomain=bg" target="_blank">
                          <LinkedInIcon />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="row"
                  style={{ height: "50%", justifyContent: "center" }}
                >
                  <div
                    style={{
                      textAlign: "center",
                      width:'80%',
                      margin:'3% auto 0% auto',
                      fontSize: "1.15rem",
                      fontFamily: "Roboto Condensed, sans-serif",
                    }}
                  >
                    Innovation Center creates business opportunities for
                    investors and startups from the US showcasing how they can
                    take advantage of available talents, low cost of business,
                    infrastructure, the entrepreneurial spirit, and the support
                    of local governments and authorities in EMEA.
                  </div>
                </div>
              </div>
            </Paper>
            <Paper
              className="container sponsor-card"
              style={{
                padding: "1rem",
                backgroundColor: "white",
                borderRadius: "10px",
                marginTop: "5vh",
              }}
              elevation={15}
            >
              <div style={{ height: "fit-content" }}>
              <div style={{display:'flex', justifyContent:'center', padding:'1%', gap:'5%', alignItems:'center'}}>
                  <h2>Gold Sponsor</h2>
                  <a href="https://www.jetro.go.jp/en/">
                        <img
                          style={{ maxWidth: "225%", padding: "1rem" }}
                          src="https://youngstartup.com/assets/media/jetro_logo.png"
                        />
                      </a>
                  </div>
                <div className="row" style={{ height: "50%", display:'flex', gap:'25%'  }}>
                  {/* <div
                    style={{ paddingTop: "2vh", position: "relative", display:'flex' }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        bottom: "5%",
                        left: "15%",
                        fontFamily: "Roboto Condensed , sans-serif !important",
                      }}
                    >
                      <div style={{ fontSize: "1.25rem" }}>Gold Sponsor</div>

                      <a href="https://www.jetro.go.jp/en/">
                        <img
                          style={{ maxWidth: "225%", padding: "1rem" }}
                          src="https://youngstartup.com/assets/media/jetro_logo.png"
                        />
                      </a>
                    </div>
                  </div> */}
                  <div
                    style={{
                      display: "grid",  
                      gridTemplateColumns: "repeat(1, 1fr)",
                      gridTemplatRows: "1fr",
                      flex: ".7",
                      margin:'auto',
                      justifyContent: "center",
                      flexWrap: "wrap",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        textAlign: "center",
                        fontFamily: "Roboto Condensed, sans-serif !important",
                      }}
                    >
                      <img
                        style={{
                          borderRadius: "100%",
                          width: "25%",
                          height: "65%",
                          boxShadow: "0px 0px 25px 10px #B1B1B1",
                   
                        }}
                        src="https://youngstartup.com/assets/media/gerald_cook.jpeg"
                      />
                      <div style={{ fontSize: "1.1rem" }}>
                        Gerald Cook,{" "}
                        <i style={{ fontSize: ".85rem" }}>
                          Business Development Representative
                        </i>{" "}
                        <a href="https://www.linkedin.com/in/geraldcook/" target="_blank">
                          <LinkedInIcon />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="row"
                  style={{ height: "50%", justifyContent: "center" }}
                >
                  <div
                    style={{
                      textAlign: "center",
                      width:'80%',
                      margin:'3% auto 0% auto',
                      fontSize: "1.15rem",
                      fontFamily: "Roboto Condensed, sans-serif",
                    }}
                  >
                    JETRO, or the Japan External Trade Organization, is a
                    government-related organization that works to promote mutual
                    trade and investment between Japan and the rest of the
                    world. Originally established in 1958 to promote Japanese
                    exports abroad, JETRO's core focus in the 21st century has
                    shifted toward promoting foreign direct investment into
                    Japan and helping small to medium size Japanese firms
                    maximize their global export potential.
                  </div>
                </div>
              </div>
            </Paper>
            <Paper
              className="container sponsor-card"
              style={{
                padding: "1rem",
                backgroundColor: "white",
                borderRadius: "10px",
                marginTop: "5vh",
              }}
              elevation={15}
            >
              <div style={{ height: "fit-content" }}>
              <div style={{display:'flex', justifyContent:'center', padding:'1%', gap:'5%', alignItems:'center'}}>
                  <h2>Gold Sponsor</h2>
                  <a href="https://mongotel.com/"><img
                          style={{ maxWidth: "225%", padding: "1rem" }}
                          src="https://youngstartup.com/assets/events/vsvc21/sponsors/mongotel-e1638444035285.png"
                        /></a> 
                  </div>
                <div className="row" style={{ height: "50%", display:'flex', gap:'25%' }}>
                  {/* <div
                    style={{ paddingTop: "2vh", position: "relative", display:'flex' }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        bottom: "5%",
                        left: "15%",
                        fontFamily: "Roboto Condensed , sans-serif !important",
                      }}
                    >
                      <div style={{ fontSize: "1.25rem" }}>Gold Sponsor</div>

                      <a href="https://mongotel.com/">
                        <img
                          style={{ maxWidth: "225%", padding: "1rem" }}
                          src="https://youngstartup.com/assets/events/vsvc21/sponsors/mongotel-e1638444035285.png"
                        />
                      </a>
                    </div>
                  </div> */}
                  <div
                    style={{
                      display: "grid",  
                      gridTemplateColumns: "repeat(1, 1fr)",
                      gridTemplatRows: "1fr",
                      flex: ".7",
                      margin:'auto',
                      justifyContent: "center",
                      flexWrap: "wrap",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        textAlign: "center",
                        fontFamily: "Roboto Condensed, sans-serif !important",
                      }}
                    >
                      <img
                        style={{
                          borderRadius: "100%",
                          width: "25%",
                          height: "85%",
                          boxShadow: "0px 0px 25px 10px #B1B1B1",
                        }}
                        src="https://youngstartup.com/assets/events/vsvc21/sponsors/david-levine-e1638290639365.jpg"
                      />
                      <div style={{ fontSize: "1.1rem" }}>
                        David Levine,{" "}
                        <i style={{ fontSize: ".85rem" }}>VP of Sales</i>{" "}
                        <a href="https://www.linkedin.com/in/davidlevinemongotel/" target="_blank">
                          <LinkedInIcon />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="row"
                  style={{ height: "50%", justifyContent: "center" }}
                >
                  <div
                    style={{
                      textAlign: "center",
                      width:'80%',
                      margin:'3% auto 0% auto',
                      fontSize: "1.15rem",
                      fontFamily: "Roboto Condensed, sans-serif",
                    }}
                  >
                    MongoTEL works reliably and includes inherent security.You
                    can grow the system easily and upgrade seamlessly to new
                    features. No matter how small or large, you'll have the
                    right bandwidth and accessibility for crystal clear
                    conversations, no matter where you make the call.
                  </div>
                </div>
              </div>
            </Paper>
            
            
          
            <Paper
              className="container sponsor-card"
              style={{
                padding: "1rem",
                backgroundColor: "white",
                borderRadius: "10px",
                marginTop: "5vh",
              }}
              elevation={15}
            >
              <div style={{ height: "fit-content" }}>
              <div style={{display:'flex', justifyContent:'center', padding:'1%', gap:'5%', alignItems:'center'}}>
                  <h2>Gold Sponsor</h2>
                  <a href="https://salesqualia.com/">
                        <img
                          style={{ maxWidth: "250%", padding: "1rem" }}
                          src="https://youngstartup.com/assets/media/Group%20109%20(1).png"
                        />
                      </a>
                  </div>
                <div className="row" style={{ height: "50%", display:'flex'}}>
                  {/* <div
                    style={{ paddingTop: "2vh", position: "relative", display:'flex' }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        bottom: "5%",
                        left: "15%",
                        fontFamily: "Roboto Condensed , sans-serif !important",
                      }}
                    >
                      <div style={{ fontSize: "1.25rem" }}>Gold Sponsor</div>

                      
                    </div>
                  </div> */}
                  <div
                    style={{
                      display: "grid",  
                      gridTemplateColumns: "repeat(2, 1fr)",
                      gridTemplateRows: "repeat(2, 1fr)",
                      flex: ".7",
                      margin:'auto',
                      justifyContent: "center",
                      flexWrap: "wrap",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        textAlign: "center",
                        fontFamily: "Roboto Condensed, sans-serif !important",
                      }}
                    >
                      <img
                        style={{
                          borderRadius: "100%",
                          width: "50%",
                          height: "85%",
                          boxShadow: "0px 0px 25px 10px #B1B1B1",
                        }}
                        src="https://youngstartup.com/assets/media/Scott%20Sambucci%20headshot%201.png"
                      />
                      <div style={{ fontSize: "1.1rem" }}>
                        Scott Sambucci,{" "}
                        <i style={{ fontSize: ".85rem" }}>Founder & CEO</i>{" "}
                        <a href="https://www.linkedin.com/in/scottsambucci/" target="_blank">
                          <LinkedInIcon />
                        </a>
                      </div>
                    </div>
                    <div
                      style={{
                        textAlign: "center",
                        fontFamily: "Roboto Condensed, sans-serif !important",
                      }}
                    >
                      <img
                        style={{
                          borderRadius: "100%",
                          width: "50%",
                          height: "85%",
                          boxShadow: "0px 0px 25px 10px #B1B1B1",
                        }}
                        src="https://youngstartup.com/assets/media/eura-edmond%201.png"
                      />
                      <div style={{ fontSize: "1.1rem" }}>
                      Eura Edmond,{" "}
                        <i style={{ fontSize: ".85rem" }}>Customer Success Director</i>{" "}
                        <a href="https://www.linkedin.com/in/euraedmond/" target="_blank"> 
                          <LinkedInIcon />
                        </a>
                      </div>
                    </div>
                    <div
                      style={{
                        textAlign: "center",
                        fontFamily: "Roboto Condensed, sans-serif !important",
                      }}
                    >
                      <img
                        style={{
                          borderRadius: "100%",
                          width: "50%",
                          height: "85%",
                          boxShadow: "0px 0px 25px 10px #B1B1B1",
                        }}
                        src="https://youngstartup.com/assets/media/amy-cox%201.png"
                      />
                      <div style={{ fontSize: "1.1rem" }}>
                          Amy Cox,{" "}
                        <i style={{ fontSize: ".85rem" }}>Head of Operations</i>{" "}
                        <a href="https://www.linkedin.com/in/coxamy/" target="_blank">
                          <LinkedInIcon />
                        </a>
                      </div>
                    </div>
                    <div
                      style={{
                        textAlign: "center",
                        fontFamily: "Roboto Condensed, sans-serif !important",
                      }}
                    >
                      <img
                        style={{
                          borderRadius: "100%",
                          width: "50%",
                          height: "85%",
                          boxShadow: "0px 0px 25px 10px #B1B1B1",
                        }}
                        src="https://youngstartup.com/assets/media/ruby-vasquez%201.png"
                      />
                      <div style={{ fontSize: "1.1rem" }}>
                      Ruby Vasquez,{" "}
                        <i style={{ fontSize: ".85rem" }}>Event Coordinator</i>{" "}
                        <a href="https://www.linkedin.com/in/annarubyvasquez/" target="_blank">
                          <LinkedInIcon />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="row"
                  style={{ height: "50%", justifyContent: "center" }}
                >
                  <div
                    style={{
                      textAlign: "center",
                      width:'80%',
                      margin:'3% auto 0% auto',
                      marginTop: "2vh",
                      fontSize: "1.15rem",
                      fontFamily: "Roboto Condensed, sans-serif",
                    }}
                  >
                   Founded in 2011, SalesQualia is dedicated to helping early stage B2B start­ups ramp up and scale up their revenue and sales process so that they can make the impact they want in their markets. Our company’s mission is to help 100 enterprise startups reach $10mm ARR in the next 10 years.
                  </div>
                </div>
              </div>
            </Paper>
            <Paper
              className="container sponsor-card"
              style={{
                padding: "1rem",
                backgroundColor: "white",
                borderRadius: "10px",
                marginTop: "5vh",
              }}
              elevation={15}
            >
              <div style={{ height: "fit-content" }}>
              <div style={{display:'flex', justifyContent:'center', padding:'1%', gap:'5%', alignItems:'center'}}>
                  <h2>Gold Sponsor</h2>
                  <a href="https://www.shrwood.com/Home">
                        <img
                          style={{ maxWidth: "225%", padding: "1rem" }}
                          src="https://youngstartup.com/assets/media/Sherwood%20Black%201.png"
                        />
                      </a>
                  </div>
                <div className="row" style={{ height: "50%", display:'flex'}}>
                  {/* <div
                    style={{ paddingTop: "2vh", position: "relative", display:'flex' }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        bottom: "5%",
                        left: "15%",
                        fontFamily: "Roboto Condensed , sans-serif !important",
                      }}
                    >
                      <div style={{ fontSize: "1.25rem" }}>Gold Sponsor</div>

                      
                    </div>
                  </div> */}
                  <div
                    style={{
                      display: "grid",  
                      gridTemplateColumns: "repeat(1, 1fr)",
                      gridTemplatRows: "1fr",
                      flex: ".7",
                      margin:'auto',
                      justifyContent: "center",
                      flexWrap: "wrap",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        textAlign: "center",
                        fontFamily: "Roboto Condensed, sans-serif !important",
                      }}
                    >
                      <img
                        style={{
                          borderRadius: "100%",
                          width: "25%",
                          height: "85%",
                          boxShadow: "0px 0px 25px 10px #B1B1B1",
                        }}
                        src="https://youngstartup.com/assets/media/martin%201.png"
                      />
                      <div style={{ fontSize: "1.1rem" }}>
                      Martin Pichinson,{" "}
                        <i style={{ fontSize: ".85rem" }}>CEO</i>{" "}
                        <a href="https://www.linkedin.com/in/pichinson/" target="_blank">
                          <LinkedInIcon />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="row"
                  style={{ height: "50%", justifyContent: "center" }}
                >
                  <div
                    style={{
                      textAlign: "center",
                      width:'80%',
                      margin:'3% auto 0% auto',
                      marginTop:'2vh',
                      fontSize: "1.15rem",
                      fontFamily: "Roboto Condensed, sans-serif",
                    }}
                  >
                   Since 1992, Sherwood Partners, Inc. (Sherwood Partners) has become one of the premier business advisory and consulting firms. Our team of seasoned business professionals provides founders, shareholders, senior executives, boards and financial institutions more than a report. We deliver ‘experience’. At Sherwood Partners, we go beyond traditional consulting and advisory services to help make decisions and implement plans that achieve exceptional results. ‘The Industry Knows Sherwood and Sherwood Knows the Industry’. GET SHERWOOD!
                  </div>
                </div>
              </div>
            </Paper>
            <Paper
              className="container sponsor-card"
              style={{
                padding: "3rem",
                backgroundColor: "white",
                borderRadius: "10px",
                marginTop: "5vh",
              }}
              elevation={15}
            >
              <div style={{ height: "fit-content" }}>
              <div style={{display:'flex', justifyContent:'center', padding:'1%', gap:'5%', alignItems:'center'}}>
                  <h2>Venture Sponsor</h2>
                  <a href="https://www.allstate.com/strategic-ventures/main.htm">
                        <img
                          style={{
                            width: "125%",
                          }}
                          src="https://youngstartup.com/assets/media/Allstate-Strategic-Ventures.png"
                        />
                      </a>
                  </div>
                <div className="row" style={{ height: "50%", display:'flex' }}>
                  {/* <div
                    style={{ paddingTop: "2vh", position: "relative", display:'flex' }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        bottom: "%",
                        left: "15%",
                        fontFamily: "Roboto Condensed, sans-serif !important",
                      }}
                    >
                      <span style={{ fontSize: "1.25rem" }}>
                        Venture Sponsor
                      </span>

                      <a href="https://www.allstate.com/strategic-ventures/main.htm">
                        <img
                          style={{
                            width: "225%",
                            marginLeft: "-3rem",
                            padding: "2rem",
                          }}
                          src="https://youngstartup.com/assets/media/Allstate-Strategic-Ventures.png"
                        />
                      </a>
                    </div>
                  </div> */}
                  <div
                    style={{
                      display: "grid",  
                      gridTemplateColumns: "repeat(2, 1fr)",
                      gridTemplatRows: "1fr",
                      flex: "1",
                      margin: 'auto',
                      flexWrap: "wrap",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        textAlign: "center",
                        fontFamily: "Roboto Condensed, sans-serif !important",
                      }}
                    >
                      <img
                        style={{
                          borderRadius: "100%",
                          width: "35%",
                          height: "75%",
                          boxShadow: "0px 0px 25px 10px #B1B1B1",
                        }}
                        src="https://youngstartup.com/assets/media/Tarik-Galijasevic.jpg"
                      />
                      <div style={{ fontSize: "1.1rem", marginTop: ".5rem" }}>
                        Tarik Galijasevic,{" "}
                        <i style={{ fontSize: ".85rem" }}>Co-Chair</i>{" "}
                        <a href="https://www.linkedin.com/in/tarik-galijasevic-1563201/" target="_blank">
                          <LinkedInIcon />
                        </a>
                      </div>
                    </div>
                    <div
                      style={{
                        textAlign: "center",
                        fontFamily: "Roboto Condensed, sans-serif !important",
                      }}
                    >
                      <img
                        style={{
                          borderRadius: "100%",
                          width: "35%",
                          height: "75%",
                          boxShadow: "0px 0px 25px 10px #B1B1B1",
                        }}
                        src="https://youngstartup.com/assets/media/Reese-Schroeder.jpeg"
                      />
                      <div style={{ fontSize: "1.1rem", marginTop: ".5rem" }}>
                        Reese Schroeder,{" "}
                        <i style={{ fontSize: ".85rem" }}>Co-Chair</i>{" "}
                        <a href="https://www.linkedin.com/in/reese-schroeder-167652/" target="_blank">
                          <LinkedInIcon />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="row"
                  style={{ height: "50%", justifyContent: "center" }}
                >
                  <div
                    style={{
                      textAlign: "center",
                      width:'80%',
                      margin:'3% auto 0% auto',
                      fontSize: "1.15rem",
                      fontFamily: "Roboto Condensed, sans-serif",
                    }}
                  >
                    Founded in 2015, Allstate Strategic Ventures (ASV) is the
                    corporate venture capital arm of Allstate Insurance Company,
                    a leader in the property and casualty insurance market in
                    the United States. We invest in entrepreneurs whose
                    disruptive technologies — ranging from insurtech to new
                    mobility models to deep and frontier tech — will help shape
                    the future of the insurance industry and adjacent sectors.
                    ASV has a long history of strategic investing and has built
                    a world-class portfolio of companies.
                  </div>
                </div>
              </div>
            </Paper>
            <Paper
              className="container sponsor-card"
              style={{
                padding: "1rem",
                backgroundColor: "white",
                borderRadius: "10px",
                marginTop: "5vh",
              }}
              elevation={15}
            >
              <div style={{ height: "fit-content" }}>
              <div style={{display:'flex', justifyContent:'center', padding:'1%', gap:'5%', alignItems:'center'}}>
                  <h2>Venture Sponsor</h2>
                  <a href="https://www.energyfoundry.com/">
                        <img
                          style={{ maxWidth: "225%", paddingTop: "2rem" }}
                          src="https://youngstartup.com/assets/events/vsvc21/sponsors/bcda75fe6b78252432383bf72adc0c1b-1-250x88.png"
                        />
                      </a>
                  </div>
                <div className="row" style={{ height: "50%", display:'flex' }}>
                  {/* <div
                    style={{ paddingTop: "2vh", position: "relative", display:'flex' }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        bottom: "5%",
                        left: "15%",
                        fontFamily: "Roboto Condensed , sans-serif !important",
                      }}
                    >
                      <span style={{ fontSize: "1.25rem" }}>
                        Venture Sponsor
                      </span>

                    
                    </div>
                  </div> */}
                  <div
                    style={{
                      display: "grid",  
                      gridTemplateColumns: "repeat(2, 1fr)",
                      gridTemplatRows: "1fr",
                      flex: "1",
                      justifyContent: "space-around",
                      flexWrap: "wrap",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        textAlign: "center",
                        fontFamily: "Roboto Condensed , sans-serif !important",
                      }}
                    >
                      <img
                        style={{
                          borderRadius: "100%",
                          width: "33%",
                          height: "85%",
                          boxShadow: "0px 0px 25px 10px #B1B1B1",
                        }}
                        src="https://youngstartup.com/assets/events/vsvc21/sponsors/jason-blumberg-e1638285854576.jpg"
                      />
                      <div style={{ fontSize: "1.1rem" }}>
                        Jason Blumberg,{" "}
                        <i style={{ fontSize: ".85rem" }}>
                          Co-Founder & Managing Manager
                        </i>{" "}
                        <a href="https://www.linkedin.com/in/jasonblumberg/" target="_blank">
                          <LinkedInIcon />
                        </a>
                      </div>
                    </div>
                    <div
                      style={{
                        textAlign: "center",
                        fontFamily: "Roboto Condensed, sans-serif !important",
                      }}
                    >
                      <img
                        style={{
                          borderRadius: "100%",
                          width: "33%",
                          height: "85%",
                          boxShadow: "0px 0px 25px 10px #B1B1B1",
                        }}
                        src="https://youngstartup.com/assets/events/vsvc21/sponsors/sara-chamberlain-e1638285967913.jpeg"
                      />
                      <div style={{ fontSize: "1.1rem" }}>
                        Sara Chamberlain,{" "}
                        <i style={{ fontSize: ".85rem" }}>Managing Director</i>{" "}
                        <a href="https://www.linkedin.com/in/sarahochmanchamberlain/" target="_blank">
                          <LinkedInIcon />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="row"
                  style={{ height: "50%", justifyContent: "center" }}
                >
                  <div
                    style={{
                      textAlign: "center",
                      width:'80%',
                      margin:'3% auto 0% auto',
                      fontSize: "1.15rem",
                      fontFamily: "Roboto Condensed, sans-serif",
                    }}
                  >
                    Energy Foundry invests venture capital in today’s most
                    promising energy & cleantech innovators, and we work with
                    the world’s leading companies to build and scale new
                    ventures.Our approach merges venture capital with the perks
                    of partnership, and includes an arsenal of essential tools
                    and relationships to help bring great ideas to market.
                  </div>
                </div>
              </div>
            </Paper>

            <Paper
              className="container sponsor-card"
              style={{
                padding: "1rem",
                backgroundColor: "white",
                borderRadius: "10px",
                marginTop: "5vh",
              }}
              elevation={15}
            >
              <div style={{ height: "fit-content" }}>
                
                <div className="row" style={{ height: "50%", display:'flex', flexDirection:'column'}}>
                <div style={{display:'flex', justifyContent:'center', padding:'1%', gap:'5%', alignItems:'center'}}>
                  
                  <a href="https://masaimpact.com/" style={{display:'flex', gap:'5%', alignItems:'center', justifyContent:'center', color:'black'}}>
                  <h2>Venture Sponsor</h2>
                        <img
                          style={{ maxWidth: "20%", paddingTop: "2rem" }}
                          src="https://youngstartup.com/assets/media/masa_partners.jpg"
                        />
                      </a>
                  </div>
                  {/* <div
                    style={{ paddingTop: "2vh", position: "relative" }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        bottom: "5%",
                        left: "15%",
                        fontFamily: "Roboto Condensed , sans-serif !important",
                      }}
                    >
                      <span style={{ fontSize: "1.25rem" }}>
                        Venture Sponsor
                      </span>

                      <a href="https://masaimpact.com/">
                        <img
                          style={{ maxWidth: "225%", paddingTop: "2rem" }}
                          src="https://youngstartup.com/assets/media/masa_partners.jpg"
                        /><div
                    style={{ paddingTop: "2vh", position: "relative" }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        bottom: "5%",
                        left: "15%",
                        fontFamily: "Roboto Condensed , sans-serif !important",
                      }}
                    >
                      <span style={{ fontSize: "1.25rem" }}>
                        Venture Sponsor
                      </span>

                  
                    </div>
                  </div>
                      </a>
                    </div>
                  </div> */}
                  <div
                    style={{
                      display: "grid",  
                      gridTemplateColumns: "repeat(2, 1fr)",
                      gridTemplatRows: "1fr",
                      flex: "1",
                      alignItems: "center",
                      flexDirection: "row",
                      paddingLeft: "2.5vw",
                    }}
                  >
                    <div
                      style={{
                        textAlign: "center",
                        fontFamily: "Roboto Condensed , sans-serif !important",
                      }}
                    >
                      <img
                        style={{
                          borderRadius: "100%",
                          width: "33%",
                          height: "85%",
                          boxShadow: "0px 0px 25px 10px #B1B1B1",
                        }}
                        src="https://youngstartup.com/assets/media/max_duckworth.jpeg"
                      />
                      <div style={{ fontSize: "1.1rem" }}>
                        Max Duckworth,{" "}
                        <i style={{ fontSize: ".85rem" }}>Co-Founder</i>{" "}
                        <a href="https://www.linkedin.com/in/max-duckworth-9b89bb52/" target="_blank">
                          <LinkedInIcon />
                        </a>
                      </div>
                    </div>
                    <div
                      style={{
                        textAlign: "center",
                        fontFamily: "Roboto Condensed, sans-serif !important",
                      }}
                    >
                      <img
                        style={{
                          borderRadius: "100%",
                          width: "33%",
                          height: "85%",
                          boxShadow: "0px 0px 25px 10px #B1B1B1",
                        }}
                        src="https://youngstartup.com/assets/media/sarah_godlewski.jpeg"
                      />
                      <div style={{ fontSize: "1.1rem" }}>
                        Sarah Godlewski,{" "}
                        <i style={{ fontSize: ".85rem" }}>Co-Founder</i>{" "}
                        <a href="https://www.linkedin.com/in/sarahgodlewski/" target="_blank">
                          <LinkedInIcon />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="row"
                  style={{ height: "50%", justifyContent: "center" }}
                >
                  <div
                    style={{
                      textAlign: "center",
                      width:'80%',
                      margin:'3% auto 0% auto',
                      fontSize: "1.15rem",
                      fontFamily: "Roboto Condensed, sans-serif",
                    }}
                  >
                    Sarah and Max met in 2013 through their volunteer board work
                    for UNICEF USA. Sarah had just left her corporate career to
                    become a public servant, while Max was taking a break after
                    twenty years in the energy & environmental arena. At the
                    time, social impact investment had been approached through a
                    philanthropic lens or with the expectation of modest, if
                    any, financial returns. A couple of years later, Max and
                    Sarah co-founded MaSa Partners to build a financially
                    sustainable venture that invests, grows and recycles
                    capital. Since inception, MaSa Partners has built a
                    multi-sector portfolio addressing some of our greatest
                    challenges, such as climate change and gender & income
                    inequality.
                  </div>
                </div>
              </div>
            </Paper>
          </div>
        </div>
        {/* <div style={{display:"block", width:"100% !important", justifyContent: "center !important", textAlign: "center", marginTop:"5vh", borderRadius:"10%", marginBottom:"7.5vh",}}>
          <h1 style={{textAlign:"center",fontWeight:"900",color:"white",fontFamily: "Roboto Condensed, sans-serif", paddingTop:"5vh", paddingBottom:"5vh"}}>
            THANK YOU TO OUR INDUSTRY PARTNERS
          </h1>
          <img
            src="https://youngstartup.com/assets/events/vsvc21/sponsors/image-1.webp"
            style={{width:"70%"}}
            alt="industry-partners"
          />
        </div>*/}
      </div>

      {/* <Paper
        className="container mobile-sponsor-view"
        style={{
          backgroundColor: "white",
          borderRadius: "10px",
          width: "100%",
        }}
      >
        <SponsorsPaper />
      </Paper> */}
    </>
  );
};
