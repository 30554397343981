import { useEffect, useState } from "react";
import { InvestorNavBar, SponsorBar } from "../components/index";
import { LockScreen } from "../pages/index";
import { getData } from "../api/calls";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Marquee from "react-fast-marquee";


var getUrl = window.location;
var baseUrl = getUrl .protocol + "//" + getUrl.host + "/" + getUrl.pathname.split('/')[1];

const logoArr = [
  { img_url: "/assets/Brownrudnick.png", website: "https://brownrudnick.com/" },
  { img_url: "/assets/Palladium Capital.png", website: "https://palladiumcapital.com/" },
  { img_url: "/assets/MWE.png", website: "https://www.mwe.com/" },
  { img_url: "/assets/boardsy.png", website: "https://boardsi.com/" },
  { img_url: "/assets/Cartika.png", website: "https://www.cartika.com/" },
  {
    img_url: "/assets/Full Throttle Leads.png",
    website: "https://fullthrottleleads.com/",
  },
  {
    img_url: "/assets/Innovation Center.png",
    website: "https://innovationcenter.tech/",
  },
  {
    img_url: "/assets/JETRO.png",
    website: "https://www.jetro.go.jp/en/",
  },
  { img_url: "/assets/mongotel.png", website: "https://mongotel.com/" },
  { img_url: "/assets/Group 109 (1).png", website: "https://salesqualia.com/" },
  {
    img_url: "/assets/Allstate.png",
    website: "https://www.allstate.com/strategic-ventures/main.htm",
  },
  {
    img_url: "/assets/Energy Foundry.png",
    website: "https://www.energyfoundry.com/",
  },
  { img_url: "/assets/Masa Partners.png", website: "https://masaimpact.com/" },
  { img_url: "/assets/Sherwood.png", website: "https://www.shrwood.com/" },
  { img_url: "/assets/Agency IP.png", website: "https://www.agencyip.com/" },
];


export const Home = () => {
  const [data, setData] = useState({});
  const [showLockscreen, setShowLockscreen] = useState(document.referrer.includes(baseUrl) ? "none" : "block");
  const [showHome, setShowHome] = useState(document.referrer.includes(baseUrl) ? "block" : "none");

  const MarqueeItem = ({img}) =>{
    return(
      <a href={img.website} target="_blank"><img src={img.img_url} style={{height:'175px', width:'200px', objectFit:'contain', padding:'0% 1%'}} /></a>
    )
  }

  useEffect(() => {
    setTimeout(()=>{
      setShowLockscreen('none')
      setShowHome('block');
    }, 2500)
    // getData("https://youngstartup.io/api/cwebsite/get_presenting_comp").then(
    //   (data) => {
    //     setData(data);
    //     console.log(showLockscreen)
    //     console.log(document.referrer.includes(baseUrl))
    //   if(!data.is_live){
       
    //     }
    //   }
    // )
  }, []);


  // data.is_live ? (
   return( <div>
      <div style={{display: showLockscreen}}>
        <LockScreen  />
      </div>
      <div style={{display: showHome}}>
      <InvestorNavBar />
      <div
        style={{
          background: "url('/assets/ny-skyline.webp')",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: "100vh",
          overflowY: "scroll",
        }}
      >
        <div style={{ display: "flex" }}>
          <Stack sx={{backgroundColor:'white'}}>
            {/* {window.innerWidth > 900 && <div style={{flex:1, display:'flex', flexDirection:'column', justifyContent:'center'}}>
              <SponsorBar />
            </div>} */}
          </Stack>
          <Stack sx={{ flex: 4, display: "flex", height: "70vh" }}>
          { window.innerWidth > 500 && window.innerHeight > 500
           && <div style={{margin:'7.5% auto', fontSize:'2rem', color: 'white', backgroundColor:'#7C2387', borderRadius:'5px', padding:'1% 1.5%', fontWeight:'900',boxShadow:"0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)"}}>Where Innovation Meets Capital&trade;</div>
          //  <img
          //     src={"/assets/Frame 168 (1).png"}
          //     style={{ maxWidth: "50%", margin: "auto", marginTop: "10%" }}
          //   /> 
            }
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                gap: window.innerWidth < 900 ? "2.5vh" : "2%",
                color: "white",
                flexDirection: window.innerWidth < 900 && window.innerHeight > 300 && 'column',
                margin:"auto",
                marginTop: window.innerWidth < 900 && window.innerHeight > 400 && "5vh",
              }}
            >
              <Stack
                style={{ display: "flex", justifyContent: "center", flex: 1 }}
                spacing={window.innerWidth < 900 ? 1 : 2}
              >
                <h1 style={{ textAlign: "center", fontWeight: "900", fontSize: window.innerHeight < 500 && ".9rem "}}>
                  Awesome Sessions
                </h1>
               { window.innerHeight > 375 && <div style={{ textAlign: "center", marginBottom:'2vh'}}>
                  Venture Panels, Startup Workshops
                </div> }
                <br/>
                <a className="ripple" href={`${baseUrl}schedule`} style={{display:'flex', alignItems:'center', justifyContent:'center',textOverflow: "ellipsis", width:'fit-content',whiteSpace: "nowrap", height:'fit-content', margin:'auto'}}><Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#7C2387 !important",
                    color: "white",
                    borderRadius: "15px",
                    textTransform: "capitalize",
                    fontFamily: "Poppins, sans-serif !important",
                    width: "fit-content",
                    margin: window.innerHeight > 300 && "auto !important",
                    transform: window.innerHeight < 500 && "scale(.4)"
                  }}
                >
                  WATCH SESSIONS
                </Button></a>
              </Stack>
              <Stack
                style={{ display: "flex", justifyContent: "center", flex: 1 }}
                spacing={window.innerWidth < 900 ? 1 : 2}
              >
                <h1 style={{ textAlign: "center", fontWeight: "900", fontSize: window.innerHeight < 500 && ".9rem " }}>
                  Top Innovators
                </h1>
               { window.innerHeight > 375 && <div style={{ textAlign: "center", marginBottom: "2vh" }}>
                  Hear live presentations from over 180 cutting edge Innovators!
                </div> }
                <br/>
                <a className="ripple" href={`${baseUrl}presenters`} style={{display:'flex',textOverflow: "ellipsis", width:'fit-content',whiteSpace: "nowrap", height:'fit-content', margin:'auto'}}><Button
                  autofocus
                  variant="contained"
                  sx={{
                    backgroundColor: "#7C2387 !important",
                    color: "white",
                    borderRadius: "15px",
                    textTransform: "capitalize",
                    fontFamily: "Poppins, sans-serif !important",
                    width: "fit-content",
                    margin: "auto !important",
                    transform: window.innerHeight < 500 && "scale(.4)"
                  }}
                >
                  FEATURED COMPANIES
                </Button></a>
              </Stack>
              <Stack
                style={{ display: "flex", justifyContent: "center", flex: 1 }}
                spacing={window.innerWidth < 900 ? 1 : 2}
              >
                <h1 style={{ textAlign: "center", fontWeight: "900", fontSize: window.innerHeight < 500 && ".9rem " }}>
                  1-on-1 Networking
                </h1>
               {window.innerHeight > 375 &&  <div style={{ textAlign: "center", marginBottom:'2vh' }}>
                  Use the Matchmaking app to setup Virtual Meetings
                </div> }
                <br/>
                <a className="ripple" href="https://matchmaking.venturesummit.tv/" target="_blank" style={{display:'flex', alignItems:'center', justifyContent:'center', margin:'0px !important',textOverflow: "ellipsis", width:'fit-content',whiteSpace: "nowrap", height:'fit-content', margin:'auto'}}><Button
                  autofocus
                  variant="contained"
                  sx={{
                    backgroundColor: "#7C2387 !important",
                    color: "white",
                    borderRadius: "15px",
                    textTransform: "capitalize",
                    fontFamily: "Poppins, sans-serif !important",
                    width: "fit-content",
                    margin: "auto !important",
                    transform: window.innerHeight < 500 && "scale(.4)"
                  }}
                >
                  START NETWORKING
                </Button></a>
              </Stack>
            </div>
          </Stack>
        </div>
        <div style={{backgroundColor:'white'}}>
        {window.innerWidth > 900 && <Marquee speed={50}>
        {logoArr.map((item, i)=>{
          return(
            <MarqueeItem img={item} key={i} />
          )
        })}
      </Marquee>}
      </div>
      </div>
      
      </div>
     
    </div>)
  // ) : (
  //   <LockScreen />
  // );
};
