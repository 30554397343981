export const SponsorsSection = () => {
  return (
    <div style={{ justifyContent: "center", padding: "2.5%", overflow: window.innerWidth < 900 && "scroll", height: window.innerWidth < 900 && '90vh' }}>
      <h1 style={{ textAlign: "center" }}>OUR PARTNERS</h1>
      <div
        style={{
          width: "60%",
          display: "flex",
          borderTop: "1px solid gray",
          margin: "auto",
          alignItems: "center",
          padding: "2.5%",
          flexDirection: window.innerWidth < 768 ? "column" : "row",
        }}
      >
        <h3 style={{ flex: 1, margin: "0" }}>diamond</h3>
        <div style={{ display: "flex", flex: 3, justifyContent: "center" }}>
          <a href="https://brownrudnick.com/" target="_blank">
            <img
              src={"https://youngstartup.com//assets/media/Frame 114.png"}
              style={{ maxWidth: "125px" }}
            />
          </a>
          <a href="https://palladiumcapital.com/" target="_blank">
            <img
              src={"/assets/Palladium Capital.png"}
              style={{ maxWidth: "125px" }}
            />
          </a>
        </div>
      </div>
      <div
        style={{
          width: "60%",
          display: "flex",
          borderTop: "1px solid gray",
          margin: "auto",
          alignItems: "center",
          padding: "2.5%",

          flexDirection: window.innerWidth < 768 ? "column" : "row",
        }}
      >
        <h3 style={{ flex: 1, margin: "0" }}>platinum</h3>
        <div style={{ display: "flex", flex: 3, justifyContent: "center" }}>
          <a href="https://www.mwe.com/" target="_blank">
            <img
              src={"https://youngstartup.com/assets/media/McDermott-Will-Emery-LLP_2021.png"}
              style={{ maxWidth: "125px", flex: "1 1 0px" }}
            />
          </a>
        </div>
      </div>
      <div
        style={{
          width: "60%",
          display: "flex",
          borderTop: "1px solid gray",
          margin: "auto",
          alignItems: "center",
          padding: "2.5%",
          flexDirection: window.innerWidth < 768 ? "column" : "row",
        }}
      >
        <h3 style={{ flex: 1, margin: "0" }}>gold</h3>
        <div
          style={{
            display: "flex",
            flex: 3,
            justifyContent: "center",
            flexWrap: "wrap",
            gap: "5%",
          }}
        >
          <a href="https://www.cartika.com/" target="_blank">
            <img
              src={"https://youngstartup.com//assets/media/Cartika-250x134.png"}
              style={{ maxWidth: "125px", objectFit: "scale-down" }}
            />
          </a>
          <a href="https://innovationcenter.tech/" target="_blank">
            <img
              src={"https://youngstartup.com//assets/media/IC-logo-2.png"}
              style={{
                maxWidth: "125px",
                objectFit: "scale-down",
                flex: "1 1 0px",
              }}
            />
          </a>
          <a href="https://www.jetro.go.jp/en/" target="_blank">
            <img
              src={"https://youngstartup.com//assets/media/Group 101.png"}
              style={{
                maxWidth: "125px",
                objectFit: "scale-down",
                flex: "1 1 0px",
              }}
            />
          </a>
          <a href="https://mongotel.com/" target="_blank">
            <img
              src={"https://youngstartup.com//assets/media/Group 99.png"}
              style={{
                maxWidth: "125px",
                objectFit: "scale-down",
                flex: "1 1 0px",
              }}
            />
          </a>
          <a href="https://www.linkedin.com/company/full-throttle-falato-leads/" target="_blank">
            <img
              src={"https://youngstartup.com//assets/media/Group 103.png"}
              style={{
                maxWidth: "125px",
                objectFit: "scale-down",
                flex: "1 1 0px",
              }}
            />
          </a>
          <a href="https://www.agencyip.com/" target="_blank">
            <img
              src={"https://youngstartup.com//assets/media/logo.png"}
              style={{
                maxWidth: "125px",
                objectFit: "scale-down",
                flex: "1 1 0px",
              }}
            />
          </a>
          <a href="https://www.shrwood.com/" target="_blank">
            <img
              src={"/assets/Group 109 (1).png"}
              style={{
                maxWidth: "125px",
                objectFit: "scale-down",
                flex: "1 1 0px",
              }}
            />
          </a>
          <a href="https://www.shrwood.com/" target="_blank">
            <img
              src={"https://youngstartup.com//assets/media/Sherwood Black 1.png"}
              style={{
                maxWidth: "125px",
                objectFit: "scale-down",
                flex: "1 1 0px",
              }}
            />
          </a>
        </div>
      </div>
      <div
        style={{
          width: "60%",
          display: "flex",
          borderTop: "1px solid gray",
          margin: "auto",
          alignItems: "center",
          padding: "2.5%",
          flexDirection: window.innerWidth < 768 ? "column" : "row",
        }}
      >
        <h3 style={{ flex: 1, margin: "0" }}>venture</h3>
        <div
          style={{
            display: "flex",
            flex: 3,
            justifyContent: "space-around",
            flexWrap: "wrap",
          }}
        >
          <a href="https://www.allstate.com/strategic-ventures/main.htm" target="_blank">
            <img
              src={"https://youngstartup.com//assets/media/Group 97 (1).png"}
              style={{ maxWidth: "125px", objectFit: "scale-down" }}
            />
          </a>
          <a href="https://www.energyfoundry.com/" target="_blank">
            <img
              src={
                "https://youngstartup.com/assets/media/bcda75fe6b78252432383bf72adc0c1b-1-250x88.png"
              }
              style={{
                maxWidth: "125px",
                objectFit: "scale-down",
                flex: "1 1 0px",
              }}
            />
          </a>
          <a href="https://masaimpact.com/" target="_blank">
            <img
              src={"https://youngstartup.com/assets/media/masa_partners.png"}
              style={{
                maxWidth: "125px",
                objectFit: "scale-down",
                flex: "1 1 0px",
              }}
            />
          </a>
        </div>
      </div>
      <div
        style={{
          width: "60%",
          display: "flex",
          borderTop: "1px solid gray",
          margin: "auto",
          alignItems: "center",
          padding: "2.5%",
          flexDirection: window.innerWidth < 768 ? "column" : "row",
        }}
      >
        <h3 style={{ flex: 1, margin: "0" }}>
          industry
          <br /> partners
        </h3>
        <div
          style={{
            display: "flex",
            flex: 3,
            justifyContent: "center",
            flexWrap: "wrap",
            marginLeft: "7.5%",
          }}
        >
          <a href="https://fintechmagazine.com/" target="_blank">
            <img
              src={"https://youngstartup.com/assets/media/FINTECH MAG.png"}
              style={{ maxWidth: "125px", objectFit: "scale-down" }}
            />
          </a>
          <a href="https://ihif.org/" target="_blank">
            <img
              src={"https://youngstartup.com/assets/media/IHIF_IP.png"}
              style={{
                maxWidth: "125px",
                objectFit: "scale-down",
                flex: "1 1 0px",
              }}
            />
          </a>
          <a href="https://www.israelstartupnetwork.com/" target="_blank">
            <img
              src={"https://youngstartup.com/assets/media/Israel Startup Network_IP (1).png"}
              style={{
                maxWidth: "125px",
                objectFit: "scale-down",
                flex: "1 1 0px",
              }}
            />
          </a>
          <a href="https://foresightcac.com/" target="_blank">
            <img
              src={"https://youngstartup.com/assets/media/Foresight Canada.png"}
              style={{
                maxWidth: "125px",
                objectFit: "scale-down",
                flex: "1 1 0px",
                marginTop:'1vh'
              }}
            />
          </a>
        </div>
      </div>
    </div>
  );
};
