//react imports
import { useEffect, useState } from "react";

//mui imports
import Stack from '@mui/material/Stack';

//local imports
import { getData } from "../api/calls";

export const LockScreen = () => {
  const [timer, setTimer] = useState(null)
  const [isLive, setIsLive] = useState(null)
  const [showLoader, setShowLoader] = useState("block")

  const sponsorLinks = [
    {img: "/assets/Brownrudnick.png", link:"https://brownrudnick.com/"},
    {img: "/assets/Palladium Capital.png", link:"https://palladiumcapital.com/"},
    {img: "/assets/MWE.png", link:"https://www.mwe.com/"},
    {img: "/assets/JETRO.png", link:"https://www.jetro.go.jp/en/"},
    {img: "/assets/mongotel.png", link:"https://mongotel.com/"},
    {img: "/assets/Full Throttle Leads.png", link:"https://fullthrottleleads.com/"},
    {img: "/assets/Cartika.png", link:"https://www.cartika.com/"},
    {img: "/assets/Innovation Center.png", link:"https://innovationcenter.tech/"},
    {img: "/assets/Allstate.png", link:"https://www.allstate.com/strategic-ventures/main.htm"},
    {img: "/assets/Energy Foundry.png", link:"https://www.energyfoundry.com/"},
    {img: "/assets/boardsy.png", link:"https://boardsi.com/"},
    {img: "/assets/Masa Partners.png", link:"https://masaimpact.com/"},
    {img: "/assets/Agency IP.png", link:"https://www.agencyip.com/"},
    {img: "/assets/Sherwood.png", link:"https://www.shrwood.com/Home"},
    {img: "/assets/Group 109 (1).png", link:"https://salesqualia.com/"}
  ]

  const getTimeRemaining = (endtime) => {
    const total = Date.parse(endtime) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
    const days = Math.floor(total / (1000 * 60 * 60 * 24));

    return {
      total,
      days,
      hours,
      minutes,
      seconds,
    };
  };

  const initializeClock = (endtime) => {
    const timeinterval = setInterval(() => {
      const t = getTimeRemaining(endtime);
      setTimer(<div style={{display:'flex', gap:'5%', fontSize:window.innerWidth < 900 && '.8rem'}}><div><h4>{t.days}</h4><div>days{" "}</div></div><div><h4>{t.hours}</h4><div>hours{" "}</div></div><div><h4>{t.minutes}</h4><div>minutes{" "}</div></div><div><h4>{t.seconds}</h4><div>seconds</div></div></div>)
      if (t.total <= 0) {
        clearInterval(timeinterval);
      }
    }, 1000);
  }

  useEffect(() => {
    getData("https://youngstartup.io/api/cwebsite/get_presenting_comp").then(
      (data) => {
        console.log(data)
        if(!data.is_live){
          initializeClock(data.count_down_to);
        }
        setIsLive(data.is_live)
      }
    )
  
  }, []);

  return (
    <div style={{height: "100vh",backgroundColor: "#2D2D2D", overflow:'scroll' }}>
      <div
        style={{
          paddingRight: "5%",
          paddingLeft: "5%",
          paddingTop:'1%',
          display: "flex",
          flexDirection: "column",
          justifyContent:'center',
          backgroundColor: "#2D2D2D", 
          height:'100%'
        }}
      >
        <h2 style={{ textAlign: "center", width: "100%", color: "white" }}>
          youngStartup Ventures welcomes you to
        </h2>

        <br/>
        <img
          src={"/assets/Frame 168 (1).png"}
          style={{
            maxWidth: window.innerWidth < 900 ? "300px" : "600px",
            margin: "0 auto",
          }}
        />
        <br />
        
        <div style={{ textAlign: "center", fontSize: "2rem", display:'flex', justifyContent:'center', color:'white', backgroundColor:window.innerWidth > 1080 && timer && !isLive && '#5F1F7C', borderRadius:'10px' }}>
          {(!timer) && <div id="loader"><div></div><div></div></div> }
         {/* { timer && !isLive && <div style={{display:'flex', alignItems:'center', gap:'5%', width:'100%', justifyContent:'center', fontSize:window.innerWidth < 900 && '.9rem', flexDirection: window.innerWidth < 900 && "column"}}><span >SUMMIT COUNTDOWN:</span><div>{timer}</div></div>} */}
        </div>
        <br />
        <Stack style={{backgroundColor:'white', borderRadius:'10px', display:'flex', height: window.innerHeight < 1000 ? '50vh' : 'fit-content', overflowY:window.innerHeight < 1000 && 'scroll'}}>
        <h2 style={{textAlign:'center'}}>Sponsored By</h2>
        <br />
        <div style={{display: "flex", flexWrap:'wrap', justifyContent:'center', overflowY: window.innerWidth < 900 && 'scroll', height: window.innerWidth < 900 && "45vh"}}>
          {
            sponsorLinks.map((link, i) =>{
              return <a href={link.link} style={{display:'flex', alignItems:'center'}}><img src={link.img} key={i} style={{flex: "0 0 calc(16.66% - 20px)", margin:'2.5px'}} /></a>
            })
          }
        </div>
        </Stack>
      </div>
      {/* <div style={{display:'flex', flexDirection:'column', backgroundColor:'white', padding:'0% 15%'}}>
      
        </div> */}
    </div>
  );
};