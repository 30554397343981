import { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { InvestorNavBar, SponsorBar } from "../components/index";
import { getData } from "../api/calls";
import presenters from "../data/pres_companies.json";

export const Presenters = () => {
  const [showTracks, setShowTracks] = useState(true);
  const [currentPresenters, setCurrentPresenters] = useState([]);
  const [title, setTitle] = useState(null);

  useEffect(() => {
    // getData("https://youngstartup.io/api/cwebsite/get_presenting_comp").then(
    //   (data) => {
    //     setPresenters(data);
    //     console.log(presenters);
    //   }
    // );
    console.log(presenters);
  }, []);

  const trackImages = [
    {
      initial: "/assets/Property 1=Default.png",
      hover: "/assets/Property 1=Variant2.png",
      title: "Tech",
      accessor: "tech",
    },
    {
      initial: "/assets/Property 1=Default (1).png",
      hover: "/assets/Property 1=Variant2 (1).png",
      title: "Fintech",
      accessor: "fin_tech",
    },
    {
      initial: "/assets/Property 1=Default (2).png",
      hover: "/assets/Property 1=Variant2 (2).png",
      title: "Cleantech",
      accessor: "clean_tech",
    },
    {
      initial: "/assets/Property 1=Default (3).png",
      hover: "/assets/Property 1=Variant2 (3).png",
      title: "Medtech",
      accessor: "medtech",
    },
    {
      initial: "/assets/Property 1=Default (4).png",
      hover: "/assets/Property 1=Variant2 (4).png",
      title: "Lifesciences & Healthcare",
      accessor: "life_sciences/healthcare",
    },
    {
      initial: "/assets/Property 1=Default (9).png",
      hover: "/assets/Property 1=Variant2 (6).png",
      title: "Seed Stage",
      accessor: "seed",
    },
    {
      initial:
        window.innerWidth < 900
          ? "/assets/Property 1=Default (8).png"
          : "/assets/Property 1=Default (6).png",
      hover: "/assets/Property 1=Variant2 (5).png",
      title: "Featured Companies",
      accessor: "",
    },
  ];

  const trackImagesMobile = [
    {
      img: "/assets/Group 113.png",
      title: "Tech",
      accessor: "tech",
    },
    {
      img: "/assets/Group 111.png",
      title: "Fintech",
      accessor: "fin_tech",
    },
    {
      img: "/assets/Group 112.png",
      title: "Cleantech",
      accessor: "clean_tech",
    },
    {
      img: "/assets/Group 110.png",
      title: "Medtech",
      accessor: "medtech",
    },
    {
      img: "/assets/Group 115.png",
      title: "Lifesciences & Healthcare",
      accessor: "life_sciences/healthcare",
    },
    {
      img: "/assets/Group 114.png",
      title: "Seed Stage",
      accessor: "seed",
    },
    {
      img: "/assets/Component 23.png",
      title: "Featured Companies",
      accessor: "",
    },
  ];

  const TrackImage = ({ track }) => {
    const [img, setImg] = useState(track.initial ? track.initial : track.img);

    return track.hover ? (
      <img
        src={img}
        onMouseEnter={(e) => setImg(track.hover)}
        onMouseLeave={(e) => setImg(track.initial)}
        onClick={(e) => {
          setShowTracks(false);
          setTitle(track.title);
          if (presenters.presenters)
            setCurrentPresenters(presenters?.presenters[`${track.accessor}`]);
          console.log(currentPresenters);
        }}
        style={{ minWidth:"50px",width: "100%", height:'auto' }}
        // loading={"eager"}
      />
    ) : (
      <img
        src={img}
        onClick={(e) => {
          setShowTracks(false);
          setTitle(track.title);
          if (presenters.presenters)
            setCurrentPresenters(presenters?.presenters[`${track.accessor}`]);
          console.log(currentPresenters);
        }}
        style={{ width: "100%", height:'auto' }}
        // loading={"eager"}
      />
    );
  };

  const TrackItem = ({ presenter, i }) => {
    return (
      <div
        style={{
          display: "flex",
          gap: "5%",
          alignItems: "center",
          flexDirection: window.innerWidth < 900 && "column",
          width: "100%",
          border: window.innerWidth < 900 && "1px solid gray",
          margin: window.innerWidth < 900 ? "5% 0%" : "2.5% 2.5%",
          fontSize: window.innerHeight < 500 && ".9rem",
        }}
      >
        <div
          style={{
            width: "250px",
            height: "100px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "1% 0%",
          }}
        >
          {presenter.logo_url ? (
            <img
              src={presenter.logo_url}
              loading={i < 3 ? "eager" : "lazy"}
              style={{
                width: "250px",
                height: "100px",
                objectFit: "scale-down",
                paddingLeft: "5%",
              }}
            />
          ) : (
            <h2
              style={{
                width: "250px",
                height: "100px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {presenter.company}
            </h2>
          )}
        </div>
        <div
          style={{
            height: "150px",
            width: "150px",
          }}
        >
          <img
            src={
              presenter.headshot_url
                ? presenter.headshot_url
                : "/assets/placeholder.jpeg"
            }
            loading={i < 3 ? "eager" : "lazy"}
            style={{
              height: "150px",
              width: "150px",
              borderRadius: "100%",
              objectFit: "cover",
            }}
          />
        </div>
        <div style={{width:window.innerWidth > 900 && '40%'}}>
          <h2
            style={{
              fontWeight: "900",
              textDecoration: "underline",
              textTransform: "capitalize",
              padding: window.innerWidth < 900 && "1rem",
            }}
          >
            {presenter.firstname} {presenter.lastname}{" "}
            {presenter.jobtitle && `, ${presenter.jobtitle}`}
          </h2>
          <div style={{ padding: window.innerWidth < 900 && "1rem" }}>
            {presenter.one_liner}
          </div>
        </div>
      </div>
    );
  };

  const TrackCompany = ({ presenter, i }) => {
    const [content, setContent] = useState(null);
    const [size, setSize] = useState("");

    const details = (
      <div>
        <Stack
          sx={{
            width: "200px",
            height: "200px",
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "0 !important",
            padding: "0 !important",
          }}
          // onMouseEnter={(e) => {
          //   setContent(details);
          // }}
          onMouseLeave={(e) => {
            presenter.logo_url
              ? setContent(img)
              : setContent(
                  <h2
                    style={{
                      width: "200px",
                      height: "200px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                    }}
                  >
                    {presenter.company}
                  </h2>
                );
          }}
        >
          {presenter.logo_url && (
            <img
              src={presenter.logo_url}
              loading={i < 10 ? "eager" : "lazy"}
              style={{
                width: "200px",
                height: "100px",
                objectFit: "scale-down",
                opacity: ".2",
              }}
            />
          )}
          <div
            style={{
              position: "absolute",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h3
              style={{
                width: "100%",
                color: "#7C2387",
                textAlign: "center",
                marginBottom: "1%",
              }}
            >
              {presenter.company}
            </h3>
            <div style={{ fontSize: ".85rem" }}>{presenter.one_liner}</div>
          </div>
        </Stack>
      </div>
    );
    const img = (
      <img
        src={presenter.logo_url}
        loading={i < 10 ? "eager" : "lazy"}
        style={{
          width: "200px",
          height: "193px",
          objectFit: "scale-down",
          opacity: "1",
        }}
      />
    );
    useEffect(() => {
      presenter.logo_url
        ? setContent(img)
        : setContent(
            <h2
              style={{
                width: "200px",
                height: "200px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              {presenter.company}
            </h2>
          );
    }, []);
    return (
      <Grid item>
        <Paper
          sx={{
            width: "250px",
            height: "200px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onMouseEnter={(e) => {
            setContent(details);
          }}
          // onMouseOut={(e) => {
          //   presenter.logo_url
          //     ? setContent(img)
          //     : setContent(
          //         <h2
          //           style={{
          //             width: "200px",
          //             height: "200px",
          //             display: "flex",
          //             alignItems: "center",
          //             justifyContent: "center",
          //             textAlign: "center",
          //           }}
          //         >
          //           {presenter.company}
          //         </h2>
          //       );
          // }}
          elevation={15}
        >
          {content}
        </Paper>
      </Grid>
    );
  };

  return (
    <div>
      <InvestorNavBar />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
       
        <div
          style={{
            flexDirection: "column",
            flex: 4,
            padding: showTracks ? "5% 2.5%" : "2% 5%",
            overflowY: window.innerWidth < 900 && "scroll",
            height: window.innerWidth < 900 && "90vh",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {showTracks ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: window.innerWidth < 900 ? "15px" : "3.5%",
                flexDirection: window.innerWidth < 900 && "column",
                // paddingTop: "5vh",
              }}
            >
              {window.innerWidth > 900 &&
                trackImages.map((image, i) => {
                  if (i !== trackImages.length - 1) {
                    return <TrackImage key={i} track={image} />;
                  }
                })}
              {window.innerWidth < 900 && (
                <Grid
                  container
                  columns={3}
                  spacing={1}
                  sx={{ display: "flex", justifyContent: "center", width:'100%' }}
                >
                  {trackImagesMobile.map((image, i) => {
                    if (i !== trackImages.length - 1) {
                      return (
                        <Grid item xs={1}>
                          <TrackImage key={i} track={image} />
                        </Grid>
                      );
                    }
                  })}
                </Grid>
              )}
              {showTracks && window.innerWidth < 900 && (
                <TrackImage
                  track={
                    window.innerWidth < 900
                      ? trackImagesMobile[trackImagesMobile.length - 1]
                      : trackImages[trackImages.length - 1]
                  }
                  style={{ width: "100%" }}
                />
              )}
            </div>
          ) : (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Paper
                elevation={15}
                sx={{
                  height: "85vh",
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  justifyContent: "center",
                  borderRadius: "0px",
                }}
              >
                <Stack sx={{ height: "100%", width: "100%" }}>
                  <div
                    className="main-navigation"
                    style={{
                      height: "15%",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      background:
                        "linear-gradient(180deg, rgba(124,35,135,1) 0%, rgba(124,35,135,1) 3%, rgba(124,35,135,1) 3%, rgba(255,255,255,1) 100%)",
                    }}
                  >
                    <div style={{ flex: 1 }}></div>
                    <h2
                      style={{
                        flex: 1,
                        marginRight:
                          title == "Lifesciences & Healthcare" ||
                          title == "Featured Companies"
                            ? "15%"
                            : "0%",
                      }}
                    >
                      {title}
                    </h2>
                    <img
                      src={"/assets/ant-design_close-circle-outlined.png"}
                      onClick={(e) => setShowTracks(true)}
                      style={{
                        marginRight: "5%",
                        transform: window.innerHeight < 500 && "scale(.3)",
                      }}
                    />
                  </div>
                  <div style={{ height: "80%", overflowY: "scroll", overflowX:'hidden' }}>
                    <div>
                      {currentPresenters?.map((arr, i) => {
                        return (
                          <div>
                            <div style={{display:'flex', justifyContent:'center'}}>
                              <h2 style={{padding: '1.5% 5%', margin:'1% 1%', backgroundColor:'#7D2C82', color:'white', borderRadius:'5px', textAlign:'center'}}>{(i == 0 || i == 1) ? "Wednesday, March 30th" : "Thursday, March 31st"}<br/>Round {i + 1}{" - "}{(i == 0 || i == 2) ? "9:00 am" : "11:00 am"}</h2></div>
                            <div>
                              {arr?.map((presenter, i) => {
                                return (
                                  <TrackItem
                                    key={i}
                                    i={i}
                                    presenter={presenter}
                                  />
                                );
                              })}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      justifyContent: "center",
                      overflowY: "scroll",
                      alignItems: "stretch",
                      display: "flex",
                      justifyContent: "center",
                      padding: "0px !important",
                    }}
                    columns={{ md: 5, lg: 5 }}
                  >
                    {title == "Featured Companies" &&
                      presenters?.all_companies &&
                      presenters?.all_companies.map((presenter, i) => {
                        console.log(presenter);
                        return (
                          <TrackCompany key={i} i={i} presenter={presenter} />
                        );
                      })}
                  </Grid>
                </Stack>
              </Paper>
            </div>
          )}
          <br />
          {showTracks && window.innerWidth > 900 && (
            <TrackImage
              track={trackImages[trackImages.length - 1]}
              style={{ width: "100%" }}
            />
          )}
        </div>
        {window.innerWidth > 900 && (
          <div style={{ backgroundColor: "white", justifyContent: "center"}}>
            <SponsorBar />
          </div>
        )}
      </div>
      
    </div>
  );
};
